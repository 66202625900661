import React from 'react';
import { getLocalizedStrings } from '../../LocalizedStrings';
let strings = getLocalizedStrings()
class OrderItem extends React.Component {
    constructor(props){
        super(props)
       
    }
    addToOrder = () => {
        this.props.openOrder(this.props.order)
    }
    render() {
        const order = this.props.order
        var completeCount = order.views_task ? order.views_task.count_coins <= 0 ? order.views_task.all_coins/2 : (order.views_task.all_coins - order.views_task.count_coins)/2 : 0
        const allCount = order.views_task ? order.views_task.all_coins/2 : 0
        const isFinished = completeCount >= allCount
        const isEmptyOrder = order.views_task == undefined
        if (allCount - completeCount < 1){
            completeCount = allCount
        }
        return (
            <div onClick={this.addToOrder} className="order_container">
                <div className="order_photo">
                    <img src={this.props.order.video.originCover} className="order_photo"/>
                </div>
                {/* <p className="order_text">{(this.props.order.all_coins - this.props.order.count_coins)/2} из {this.props.order.all_coins/2}</p>
                <ProgressLine all_coins={this.props.order.all_coins} count_coins={this.props.order.count_coins}/> */}
                <div className="my_add_container">
                    {isEmptyOrder ? 
                        <p className="my_ads_text">{strings.createOrderBtn}</p> :
                        <p style={isFinished ? {background : '#FF4081', borderRadius : '4px'} : {}} className="my_ads_text">{completeCount}<span style={{opacity : 0.5}}> / {allCount}</span></p>}
                </div>
            </div>
           
        );
    }
}
export default OrderItem; 