import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl, isShownCheckToGoogle, getToken, setShownCheckToGoogle, getCookie, saveNewCookies } from '../pages_android/custom_views/utils_android/CookieHelper';
import help_circle from './icons_android/help_circle.svg'
import chevron_right from './icons_android/chevron_right.svg'
import plus from './icons_android/plus.svg'
import OrdersBottomSheet from './custom_views/OrdersBottomSheet';
import OrderItem from './list_items/OrderItem';
import LocalizedStrings from 'react-localization'
import CheckToGoogle from './custom_views/CheckToGoogle';
import TypeTaskSheet from './custom_views/TypeTaskSheet';
import { getLocalizedStrings } from '../LocalizedStrings';
import { getHttpParams, getTestOrder } from './custom_views/utils_android/Utils';
import OrderInfo from './OrderInfo';
import LoaderCenter from './custom_views/utils_android/LoaderCenter';
import CreateOrder from './CreateOrder';
import BtnLoader from './custom_views/earn_tasks/BtnLoader';
import BuyFollowersSheet from './custom_views/BuyFollowersSheet';
let strings = getLocalizedStrings()
class Orders extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isBottomSheetOpened : false,
            isTypeOrderShown : false,
            isShowBuyFollowers : false,
            pickedFilter : strings.all,
            pickedFilterType : 0,
            filteredOrders : [],
            orders : [],
            //testing
            // pickedOrder : {},
            
            pickedOrder : getTestOrder(),
            //testing
            isShowCheckToGoogle : false,
            
            isLoading : false,
            avatar : getCookie("avatarLarger"),
            followerCount : getCookie("followerCount"),
            followersTaskCounter : "0/0",
            myAdsLoading : false
        }
       
        
    }
    componentDidMount(){
        this.getUserFeed()
    }
    getUserFeed = () => {
        this.setState({isLoading : true, myAdsLoading : true})
        var params = getRequestParams()
        params.username = getCookie("uniqueId")
        fetch(getRequestUrl() + "getUserFeed", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            this.setState({orders : responseData, isLoading : false})
            console.log("getUserFeed" + JSON.stringify(responseData))
            this.getMyAds(responseData)
        })
    }
    toggleBottomSheet = () => {
        this.setState({isShowBuyFollowers : !this.state.isShowBuyFollowers})
    }
    getMyAds = (orders) => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyAds", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            const relation = responseData.photos_relation2
            if (relation && relation.length > 0 && orders){
                orders.forEach(order => {
                    relation.forEach(element => {
                        if (order.video.id == element.media_id){
                            if (element.type == 0){
                                order.views_task = element
                            } else if (element.type == 1){
                                order.followers_task = element
                            } else if (element.type == 2){
                                order.likes_task = element
                            } else if (element.type == 3){
                                order.comments_task = element
                            } else if (element.type == 4){
                                order.shares_task = element
                            }
                        }
                    })
                })
                this.setState({orders : orders})
            }
            this.setState({followersTaskCounter : (responseData.count_incoming_followers + "/" + responseData.count_all_followers), myAdsLoading : false})
            if (orders){
                this.loadUserInfo()
            }
            
            console.log("getMyAds" + JSON.stringify(responseData))
            console.log("getMyAds result" + JSON.stringify(orders))
            
        })
    }
    setReviewGooglePlay = () => {
        var params = getRequestParams()
        fetch(getRequestUrl() + "setReviewGooglePlay", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
           
        })
    }
    createOrder = () => {
        this.setState({isNewOrder : true})
        this.props.toggleCreateOrder()
    }
    isTypeOrderShownToggle = () => {
        this.setState({isTypeOrderShown : !this.state.isTypeOrderShown})
    }
    isBottomSheetShownToggle = () => {
        this.setState({isBottomSheetOpened : !this.state.isBottomSheetOpened})
    }
    updateOrder = (order) => {
        this.setState({pickedOrder : order, isCreateOrder : true, isNewOrder : false})
    }
    openBottomSheet = () => {
        this.setState({isBottomSheetOpened : true})
    }
    loadUserInfo = () => {
        var p = getRequestParams()
        var params = {}
        params.username = p.uniqueId
        fetch(getRequestUrl() + "getInfoUser", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData){
                saveNewCookies(responseData)
                this.setState({avatar : responseData.avatarLarger, followerCount : responseData.stats.followerCount})
            }
        })
    }
    onPicked = (type, text) => {
        var filterdOrders = this.state.orders.filter(order => {
            if(type == 0){
                return true
            } else if (type == 1){
                return (order.count_coins > 1)
            } else {
                return (order.count_coins == 0)
            }
        });
        this.setState({pickedFilter : text, pickedFilterType : type, filteredOrders : filterdOrders, isBottomSheetOpened : false})
    }
    onTypePicked = (type) => {
        var order = {type : type}
        this.setState({pickedOrder : order, isNewOrder : true})
        this.props.toggleCreateOrder()
    }
    cancelCheckToGoogle = () => {
        this.setState({isShowCheckToGoogle : false})
    }
    addFollowers = () => {

    }
    openOrder = (order) => {
        this.setState({pickedOrder : order})
        this.props.toggleOrderInfo()
    }
    updateMoney = (money) => {
        this.props.updateMoney(money)
        this.getMyAds()
        this.setState({isShowBuyFollowers : false})
    }
    render() {
        if (this.props.isCreateOrder){
            //return(<div>asd</div>)
            return(<CreateOrder order={this.state.pickedOrder} count_coins={this.props.count_coins} updateMoney={this.props.updateMoney} timestamp={this.props.timestamp}/>)
        } else if(this.props.isShowOrderInfo) {
            return(<OrderInfo createOrder={this.createOrder} order={this.state.pickedOrder} count_coins={this.props.count_coins} updateMoney={this.props.updateMoney} timestamp={this.props.timestamp}/>)

        } else {
            return (
                <div className="relative main_container">
                    <img style={{marginTop : '56px'}} className="avatar_img center_img" src={this.state.avatar}/>
                    <p className="username_title">{getCookie("uniqueId")}</p>
                    <div className="followers_container">
                        <div className="follower_left">
                            <p className="follower_title">{strings.followersCount}</p>
                            <div className="relative">
                                {this.state.followerCount != "" ? <p className="follower_text">{this.state.followerCount}</p> : <BtnLoader style={{marginTop : '12px'}}/>}
                            
                            </div>
                            
                        </div>
                        <div className="follower_right">
                            <p className="follower_title">{strings.added}</p>
                            <div className="relative">
                                {!this.state.myAdsLoading ? <p className="follower_text">{this.state.followersTaskCounter}</p> : <BtnLoader style={{marginTop : '12px'}}/>}
                            </div>
                        </div>
                    </div>
                    <div onClick={this.toggleBottomSheet} className="relative add_followers_btn center_all">
                        <div className="btn-follow">
                            {strings.addFollowers}
                        </div>
                    </div>
                    {this.state.isLoading ? <LoaderCenter /> : <div className="flex new_orders_container space_between">
                        {this.state.orders.length > 0 && this.state.orders.map((item, index) => (
                            <OrderItem openOrder={this.openOrder} order={item} key={index}/>
                        ))}
                    </div>}
                    {this.state.isShowBuyFollowers && <BuyFollowersSheet updateMoney={this.updateMoney} count_coins={this.props.count_coins} toggleBottomSheet={this.toggleBottomSheet} isShown={this.state.isShowBuyFollowers}/>}
                </div>
            );
        }
        
    }
}
export default Orders;