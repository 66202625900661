import React from 'react';
import { openUrl } from '../pages_android/custom_views/utils_android/AndroidMethods';
import logo from './icons_android/logo_menu.svg'
import { getToken, getIsTimestamped, saveNewCookies } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../LocalizedStrings';
let strings = getLocalizedStrings()
class MenuAndroid extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            count_money : 0.1,
            isShowShop : true
        }
    }
    componentDidMount(){
        console.log("this.props.count_money_r = " + this.props.count_money_r)
        this.setState({count_money : this.props.count_money_r})
    }
    openAnswers = () => {
        openUrl("https://vk.com/@muchmoneyapp-questions")
    }
    exit = () => {
        saveNewCookies({})
        window.href.location = '/auth'
    }
    render() {
        return (
            
            <div className="aside__wrapper">
                <nav>
                <img className="logo_icon" src={logo}></img>
                {/* <div className="avatar_container">
                    <img className="avatar_icon" src={this.props.photo} alt=""/>
                </div>
                <p className="username">username</p> */}
                 
                {getIsTimestamped() != 'isTimestamped' ? 
                <ul className="nav">

                    <li><a className={window.location.pathname == "/search" ? "nav__item-selected" : "nav__item"} href="/search">{strings.searchTags}</a></li>
                    <li><a className={window.location.pathname == "/popular" ? "nav__item-selected" : "nav__item"} href="/popular">{strings.popularTags}</a></li>
                    <li><a className={window.location.pathname == "/shop" ? "nav__item-selected" : "nav__item"} href="/shop">{strings.buyCoins}</a></li>
                
                </ul>
                :  
                <ul className="nav">

                    <li><a className={window.location.pathname == "/orders" ? "nav__item-selected" : "nav__item"} href="/orders">{strings.promotion}</a></li>
                    <li><a className={window.location.pathname == "/earn" ? "nav__item-selected" : "nav__item"} href="/earn">{strings.getCoins}</a></li>
                    <li><a className={window.location.pathname == "/shop" ? "nav__item-selected" : "nav__item"} href="/shop">{strings.buyCoins}</a></li>
                    <li><a onClick={this.exit} className="nav__item" href="">{strings.exit}</a></li>
                   
                </ul>}
                {/* <p className="username bot_24">Пользовательское соглашение</p> */}
                <p className="username">{strings.email}<br/>admin@tik2top.com</p>
                </nav>
            </div>


        );
    }
}
export default MenuAndroid;