import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl, getCookie } from '../pages_android/custom_views/utils_android/CookieHelper';
import border from './custom_views/custom_icons/border.svg'
import link from './icons_android/link.svg'
import play from './icons_android/play.svg'
import { declension, getHttpParams } from './custom_views/utils_android/Utils';
import {DebounceInput} from 'react-debounce-input';
import eyes_icon from '../pages_android/custom_views/custom_icons/eyes.svg'
import followers_icon from '../pages_android/custom_views/custom_icons/followers.svg'
import ProgressLineCreate from './custom_views/ProgressLineCreate';
import { getLocalizedStrings } from '../LocalizedStrings';
import Switch from "react-switch"
import LoaderCenter from './custom_views/utils_android/LoaderCenter';
import BtnLoader from './custom_views/earn_tasks/BtnLoader';

let strings = getLocalizedStrings()

class CreateOrder extends React.Component {
    constructor(props){
        super(props)
        var progress = undefined
        var photo_id = this.props.order.photo_id
        var url = this.props.order.photo_path_standart
        var all_coins = this.props.order.all_coins
        var count_coins = this.props.order.count_coins
        if (count_coins){
            progress = ((all_coins - count_coins)/count_coins)*100
            console.log("progress = " + progress)
        }
        this.state = {
            isBottomSheetOpened : false,
            taskCount : 100,
            
            isLoading : false,
            coinsDeclension : strings.coins_5,
            isFailedPhoto : false,
            priceCount : 200,
            isShowBuyCoins : false,

            url : (this.props.order.media_url ? this.props.order.media_url : ""),
            video_id : photo_id,
            progress : progress,
            photo_id : photo_id,
            all_coins : all_coins,
            count_coins : count_coins,
            photoUrl : (url ? url : ""),
            isNewOrder : this.props.order.isNewOrder,

            isAddLikes : false,
            isAddComments : false,
            isAddShares : false,
            isAddFollowers : false,
            
        }
        this.onLikesChanged = this.onLikesChanged.bind(this);
        this.onCommentsChanged = this.onCommentsChanged.bind(this);
        this.onSharesChanged = this.onSharesChanged.bind(this);
        this.onFollowersChanged = this.onFollowersChanged.bind(this);
        
    }

    createOrder = () => {
        console.log("createOrder")
        // if (this.state.photoUrl == ""){
        //     return
        // }
        if (this.state.priceCount > this.props.count_coins){
            this.setState({isShowBuyCoins : true, isLoading : false})
            return
        } else {
            this.setState({isShowBuyCoins : false, isLoading : false})
        }
        this.setState({isLoading : true})
        var params = getRequestParams()
        params.photo_path_standart = this.props.order.video.originCover
        params.photo_path_low = this.props.order.video.originCover
        params.is_active = 1;
        params.photo_id = this.props.order.video.id
        params.media_id = this.props.order.video.id
        params.media_url = "https://www.tiktok.com/@" + getCookie("uniqueId") + "/video/" + this.props.order.video.id
        params.add_coins = this.state.taskCount * 2
        params.is_photo = false
        params.type = this.getType(this.state.isAddLikes, this.state.isAddComments, this.state.isAddShares, this.state.isAddFollowers)
        console.log("setPhotoState params" + JSON.stringify(params))
        fetch(getRequestUrl() + "setPhotoState", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == "1" || responseData.response == "2"){
                //this.props.updateMoney(-this.state.priceCount)
                window.location.href = '/orders?rand=' + Math.random()
            }
            this.setState({isLoading : false})
            console.log("setPhotoState" + JSON.stringify(responseData))
            
        })
       
    }
    getType = (isAddLikes, isAddComments, isAddShares, isAddFollowers) => {
        var types = "0"
        if (isAddLikes){
            types += ",2"
        }
        if (isAddComments){
            types += ",3"
        }
        if (isAddShares){
            types += ",4"
        }
        if (isAddFollowers){
            types += ",1"
        }
        return types
    }
    onCountChanged = (event) => {
        if (event){
            var count = event.target.value
            this.setState({taskCount : count})
            this.calcPrice(this.state.isAddLikes, this.state.isAddComments, this.state.isAddShares, this.state.isAddFollowers, count)
        }
        
    }
    onLikesChanged(checked){
        console.log("onLikesChanged = " + checked)
        this.setState({isAddLikes : checked})
        this.calcPrice(checked, this.state.isAddComments, this.state.isAddShares, this.state.isAddFollowers, this.state.taskCount)
    }
    onCommentsChanged(checked){
        this.setState({isAddComments :checked})
        this.calcPrice(this.state.isAddLikes, checked, this.state.isAddShares, this.state.isAddFollowers, this.state.taskCount)
    }
    onSharesChanged(checked){
        console.log("isAddShares = " + checked)
        this.setState({isAddShares : checked})
        this.calcPrice(this.state.isAddLikes, this.state.isAddComments, checked, this.state.isAddFollowers, this.state.taskCount)
    }
    onFollowersChanged(checked){
        console.log("isAddFollowers = " + checked)
        this.setState({isAddFollowers : checked})
        this.calcPrice(this.state.isAddLikes, this.state.isAddComments, this.state.isAddShares, checked, this.state.taskCount)
    }
    calcPrice = (isAddLikes, isAddComments, isAddShares, isAddFollowers, taskCount) => {
        var multiplier = 2
        if (isAddLikes){
            multiplier += 2
        }
        if (isAddComments){
            multiplier += 2
        }
        if (isAddShares){
            multiplier += 2
        }
        if (isAddFollowers){
            multiplier += 2
        }
        console.log("(multiplier*taskCount) = " + (multiplier*taskCount))
        this.setState({priceCount : (multiplier*taskCount)})
    }
    onUrlChanged = (event) => {
        if (event){
            this.setState({url : event.target.value})
            if (event.target.value.includes("tiktok")) {
                this.setState({isLoading : true, isFailedPhoto : false,  photoUrl : ""})
                var params = getRequestParams()
                params.url = event.target.value
                fetch(getRequestUrl() + "getPhotoByURL", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == "1"){
                        this.setState({isLoading : false, photoUrl : responseData.videoMeta.imageUrl, video_id : responseData.videoMeta.id})
                    } else {
                        this.setState({isFailedPhoto : true, isLoading : false, photoUrl : ""})
                    }
                    console.log("getPhotoByURL" + JSON.stringify(responseData))
                    
                })
            }
            
        }
    }
    openBottomSheet = () => {
        this.setState({isBottomSheetOpened : true})
    }
    buyCoins = () => {
        window.location.href = '/shop'
    }
    
    render() {

        return (
            <div className="create_order_bg relative main_container">
                <img className="order_info_img center_all" src={this.props.order.video.originCover}/>
                <p className="create_order_t">{strings.buyWrapping}</p>
                {/* <p className="create_order_desc">{strings.buyWrappingDesc}<br/>{strings.buyWrappingDesc2}</p> */}
                <p style={{marginTop : '32px'}} className="create_order_count_t">{strings.countViews}</p>
                <div className="create_order_count_in relative center_all">
                    <input onChange={this.onCountChanged} value={this.state.taskCount} className="create_order_count_input"/>
                </div>
                <div className="line_create_order"/>
                <p style={{fontSize : '16px', margin : '22px 0px 15px 0px'}} className="create_order_t">{strings.addToViews}</p>
                <div className="switch_line">
                    <div className="flex checkbox_line">
                        <Switch onChange={this.onLikesChanged} checked={this.state.isAddLikes} onColor="#c7e3e5" onHandleColor="#33C6CC" handleDiameter={18} offColor="#ebeef1" offHandleColor="#BDC7CE" uncheckedIcon={false} checkedIcon={false} height={24} width={40}/>
                        <p className="checkbox_label">{strings.likes}</p>
                    </div>
                    <p className="switch_text">{strings.plusCoins}</p>
                </div>
                <div className="switch_line">
                    <div className="flex checkbox_line">
                        <Switch onChange={this.onCommentsChanged} checked={this.state.isAddComments} onColor="#c7e3e5" onHandleColor="#33C6CC" handleDiameter={18} offColor="#ebeef1" offHandleColor="#BDC7CE" uncheckedIcon={false} checkedIcon={false} height={24} width={40} />
                        <p className="checkbox_label">{strings.comments}</p>
                    </div>
                    <p className="switch_text">{strings.plusCoins}</p>
                </div>
                <div className="switch_line">
                    <div className="flex checkbox_line">
                        <Switch onChange={this.onSharesChanged} checked={this.state.isAddShares} onColor="#c7e3e5" onHandleColor="#33C6CC" handleDiameter={18} offColor="#ebeef1" offHandleColor="#BDC7CE" uncheckedIcon={false} checkedIcon={false} height={24} width={40}/>
                        <p className="checkbox_label">{strings.shares}</p>
                    </div>
                    <p className="switch_text">{strings.plusCoins}</p>
                </div>
                {/*<div className="switch_line">
                    <div className="flex checkbox_line">
                        <Switch onChange={this.onFollowersChanged} checked={this.state.isAddFollowers} onColor="#c7e3e5" onHandleColor="#33C6CC" handleDiameter={18} offColor="#ebeef1" offHandleColor="#BDC7CE" uncheckedIcon={false} checkedIcon={false} height={24} width={40}/>
                        <p className="checkbox_label">{strings.followers}</p>
                    </div>
                    <p className="switch_text">{strings.plusCoins}</p>
                </div>*/}
                <div className="create_order_btn_n">
                    <p className="create_order_summ">{strings.priceOfOrder}<span style={{fontWeight : '700'}}> {this.state.priceCount} {declension(this.state.priceCount, strings.coins_1, strings.coins_2, strings.coins_5)}</span></p>
                    <div onClick={this.createOrder} style={this.state.isLoading ? {background : 'black', color : 'white', pointerEvents : 'none'} : {background : 'black', color : 'white'}} className="btn-follow top_10">
                        {this.state.isLoading ? <BtnLoader style={{marginTop : '-4px'}}/> : strings.createOrderBtn}
                    </div>
                </div>

                {this.state.isShowBuyCoins && <div onClick={this.buyCoins} className="not_enought_container">
                    <p className="not_enought_text">{strings.notEnoughtCoins}</p>
                    <p style={{fontWeight : '700'}} className="not_enought_text">{strings.buy}</p>
                </div>}
                {/* <p className="title_create_order">{!this.props.isNewOrder ? strings.editOrder : this.props.order.type == 0 ? strings.newOrder : strings.orderFollowers}</p>
                <p className="text_create_order">1 {strings.view} - 2 {strings.coins_2}</p>
                <div className="flex new_order">
                    <div className="relative">
                        <div className="image_container">
                            <img src={border}/>
                            <img src={this.props.order.type == 0 ? eyes_icon : followers_icon} className="center_img absolute_pos"/>
                        </div>
                        
                        
                    </div>
                    
                    <div className="block inputs width_100">
                        <div className={!this.props.isNewOrder ? "not_display" : "block"}>
                            <p className="text_create_order font_14 top_text_input">{this.props.order.type == 0 ? strings.link : strings.profileLink}</p>
                            <div style={this.state.isFailedPhoto ? {border : '2px solid var(--tiktok_red)'} : {border : '2px solid rgba(152,175,183,.3)'}} className="input_container relative">
                                 <DebounceInput autoComplete="off" debounceTimeout={500} onChange={this.onUrlChanged} value={this.state.url} className="width_100 input_text" />
                                <img className="input_icon" src={link}/>
                            </div>
                        </div>

                        {!this.props.isNewOrder ? <div className="block bot_20">
                            <p className="order_text order_text_black">{(this.state.all_coins - this.state.count_coins)/2} из {this.state.all_coins/2}</p>
                            <ProgressLineCreate all_coins={this.state.all_coins} count_coins={this.state.count_coins}/>
                        </div> : <div></div>}
                        
                        

                        <p className="text_create_order font_14 bot_text_input">{this.props.order.type == 0 ? strings.countViews : strings.countFollowrs}</p>
                        <div className="input_container relative">
                            <input onChange={this.onCountChanged} value={this.state.taskCount} className="width_100 input_text" />
                            <img className="input_icon" src={play}/>
                            <p className="font_14 coins_sum">{this.state.priceCount} {this.state.coinsDeclension}</p>
                        </div>
                    </div>
                </div>

                <div onClick={this.createOrder} style={(this.state.photoUrl != "" && this.state.taskCount && this.state.taskCount > 0 && this.state.url && this.state.url.length > 2) ? {opacity : '1', pointerEvents : 'auto'} : {opacity : '0.1', pointerEvents : 'none'}} className="create_order_btn">
                    {!this.props.isNewOrder ? strings.addToOrder : strings.createOrder}
                </div>
                <div onClick={this.buyCoins} className={this.state.isShowBuyCoins ? "buy_coins_container" : "not_display"}>
                    <p className="buy_coins_text">{strings.noMoney}</p>
                    <p className="buy_coins_text buy_coins_text_bold">{strings.buy}</p>
                </div> */}


            </div>
           
        );
    }
}
export default CreateOrder;