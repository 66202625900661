import React from 'react';

import LocalizedStrings from 'react-localization'
import { showAndroidToast, openUrl, copyId } from './utils_android/AndroidMethods';
import { getHttpParams } from './utils_android/Utils';
import { getRequestUrl, getRequestParams } from './utils_android/CookieHelper';

let strings = new LocalizedStrings({
    ru:{
        howYouAssess:"Стоимость копирования хэштегов - 20 монет. Дейвительно хотите их скопировать??",
        bad:"Нет",
        good:"Да",       
        notEnoungt:"Недостаточно монет. Сначала купите монеты в разделе магазин.",       
        
      },
      en: {
        howYouAssess: "The cost of copying hashtags is 20 coins. Would you really like to copy them ??",
         bad: "No",
         good: "Yes",
         notEnoungt: "Not enough coins. First buy coins in the store section.",
      }
   });
class CopyCostDialog extends React.Component {
    constructor(props){
        super(props)

    }
    successClicked = () => {
        if (this.props.count_coins >= 20){
            var params = getRequestParams()
            params.count = 20
            fetch(getRequestUrl() + "deleteCoins", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    copyId(this.props.pickedTags, this.props.succesText)
                    this.props.updateMoney(-20)
                    this.props.cancelCheckToGoogle()
                })
        } else {
            showAndroidToast(strings.notEnoungt)
            this.props.cancelCheckToGoogle()
        }
        
        
    }
    
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                            <p className="title_check font_16">{strings.howYouAssess}</p>
                            <div className="check_btns">
                                 <div onClick={this.successClicked} className="ok_btn">
                                    <p className="like_title">{strings.good}</p>
                                 </div>

                                 <div onClick={() => this.props.cancelCheckToGoogle()} className="cancel_container">
                                    <p className="dislike_title">{strings.bad}</p>
                                 </div>
                                
                            </div>
                            
                    </div>  
                </div>
            ); 
        
        
    }
}
export default CopyCostDialog;