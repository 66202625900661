import React from 'react';
import x_close_bottom from './custom_icons/x_close_bottom.svg'
import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    ru:{
        all:"Все",
        inProcess:"В процессе",
        finished:"Завершенные",
        showOrders:"Показывать заказы",
        
      },
      en: {
        all:"All",
        inProcess:"In process",
        finished:"Finished",
        showOrders:"Show orders",
      }
   });
class OrdersBottomSheet extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentSelected : 0
        }
    }
    
    
    onPicked = (type, text) => {
        console.log("onPicked sheet = " + type)
        
        this.props.onPicked(type, text)
        this.setState({currentSelected : type})
    
    }
    
    render() {
        return (
            <div className={this.props.isShown ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden'}>
                <div onClick={this.props.toggleBottomSheet} className={this.props.isShown ? 'animation_bg animateAppealBgShow' : 'animation_bg animateAppealBgHide'}>

                </div>
                <div className={this.props.isShown ? 'appeal_container_shown appeal_container_style' : 'appeal_container_hide appeal_container_style'}>
                    <div className="block padding_16 relative">
                        <p className="task_name appeal_text_task_money size_22">{strings.showOrders}</p>
                        <img onClick={this.props.toggleBottomSheet} className="x_close_bottom" src={x_close_bottom} />
                        <p style={this.state.currentSelected == 0 ? {color : '#FF4081'} : {color : 'black'}} onClick={() => this.onPicked(0, strings.all)} className="task_name appeal_text_task_money">{strings.all}</p>
                        <p style={this.state.currentSelected == 1 ? {color : '#FF4081'} : {color : 'black'}} onClick={() => this.onPicked(1, strings.inProcess)}  className="task_name appeal_text_task_money">{strings.inProcess}</p>
                        <p style={this.state.currentSelected == 2 ? {color : '#FF4081'} : {color : 'black'}} onClick={() => this.onPicked(2, strings.finished)} className="task_name appeal_text_task_money bottom_64">{strings.finished}</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default OrdersBottomSheet;