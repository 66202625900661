
export function showAndroidToast(toast) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        window.Android.showToast(toast);
    }
}

export function isAppInstalled(bundle) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.isAppInstalled(bundle);
    } else {
        return false
    }
}

export function openUrl(url) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.openUrl(url);
    } else {
        return false
    }
}

export function openApp(bundle) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.openApp(bundle);
    } else {
        return false
    }
}
export function registrationFailed(desc) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.registrationFailed(desc);
    } else {
        return false
    }
}
export function buyCoinsAndroid(productId) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.buyCoins(productId);
    } else {
        return false
    }
}
export function initBilling() {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.initBilling();
    } else {
        return false
    }
}
export function finish() {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.finish();
    } else {
        return false
    }
}
export function copyId(id, text) {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
        return window.Android.copyId(id, text);
    } else {
        return false
    }
}