import LocalizedStrings from 'react-localization'
export const getLocalizedStrings = () => {
    let strings = new LocalizedStrings({
        ru:{
            coins_1:"монета",
            coins_2:"монеты",
            coins_5:"монет",
            searchHashtags:"Поиск хештегов",
            searchDescription:"Введите ключевое слово, чтобы найти списки подходящих хештегов",
            searchPlaceholder:"Например, кот",
            pickedTagsTitle:"Выбранные хештеги",
            tagsCopied:"Хэштеги скопированы",
            notFound : "Ничего не найдено.",
            notFound2 : "Попробуйте поискать по-другому",
            searchHashtags:"Популярные хештеги",
            pickedTagsTitle:"Выбранные хештеги",
            tagsCopied:"Хэштеги скопированы",
            promote:"Продвигать профиль",
            create:"Создать",
            order:"заказ",
            all:"Все",
            getCoins:"Получить монеты",
            viewsTitle:'Перейди в TikTok, посмотри минимум 10 сек. При желании поставь лайк и напиши комментарий',
            followersTitle:'Перейди в TikTok и подпишись на аккаунт.',
            followersTitle2:'НЕ отписывайся.',
            view:"просмотр",
            editOrder:"Редактировать заказ",
            newOrder:"Заказать просмотры",
            link:"Cсылка на видеоролик",
            countViews:"Количество просмотров",
            createOrder:"Создать заказ",
            noMoney:"Недостаточно монет на балансе",
            buy:"Купить",
            orderFollowers:"Заказать подписчиков",
            profileLink:"Ссылка на профиль",
            countFollowrs:"Количество подписчиков",
            addToOrder:"Добавить в заказ",
            buyCoins:"Купить монеты",
            thisIs:"это",
            more:"Ещё предложения",
            promotion:"Продвигать профиль",
            getCoins:"Получить монеты",
            email:"Email для связи с нами",
            buyCoins:"Купить монеты",
            searchTags:"Поиск тегов",
            popularTags:"Популярные теги",
            appeal:"Пожаловаться",
            watch:"Посмотреть",
            follow:"Подписаться",
            usernamePlaceholder:"логин TikTok",
            enterBtn:"Войти",
            followers:"подписчики",
            added:"добавлено",
            addFollowers:"добавить подписчиков",
            viewsCount:"Просмотры",
            likesCount:"Лайки",
            shareCount:"Поделились",
            commentsCount:"Комменты",
            progress:"Выполнение",
            createOrderBtn:"Продвинуть",
            buyWrapping:"Заказать продвижение",
            buyWrappingDesc:"Цена зависит от длины ролика",
            buyWrappingDesc2:"20 секунд = 2 монеты",
            addToViews:"К просмотрам добавить",
            plusCoins:"+2 монеты",
            likes:"Лайки",
            comments:"Комментарии",
            shares:"Поделиться",
            followers:"Подписаться",
            priceOfOrder:"Стоимость заказа",
            notEnoughtCoins:"Недостаточно монет на балансе",
            exit:"Выйти",
            followersCount:"подписчики",
            goToVideo : "Перейти к ролику",
            goToAuthor : "Перейти",
            viewTaskDesc : "Посмотри ролик минимум 10 секунд",
            likeTaskDesc : "Поставить лайк",
            shareTaskDesc : "Поделиться роликом",
            shareTaskDesc2 : "Скопировать ссылку и отправить кому-то в любом мессенджере",
            commentTaskDesc : "Написать подходящий положительный комментарий",
            commentTaskDesc2 : "Комментарий должен соответствовать содержанию ролика.",
            followTaskDesc : "Подписаться на автора ролика",
            reward : "Вознаграждение начисляется только при выполнении всех действий",
            authError : "Такое имя не найдено. Проверьте правильность написания вашего username",
            taskErrorText : "Данные не всегда обновляются быстро, поэтому наша система не засекла ваше дествие. Если вы действительно выполнили задачи то, нажмите \"Проверить еще раз\", либо выполните задание снова",
            taskErrorBtn : "Проверить еще раз",
            promoteAcc : "Продвижение аккаунта",
            promoteText : "Накручивайте лайки, подписчиков и просмотры в Tik Tok и других социальных сетях",
            //not localized

        },
        en : {
            promoteAcc : "Promote account",
            promoteText : "Get likes, followers and views on Tik Tok and other social networks",
            coins_1:"coin",
            coins_2:"coins",
            coins_5:"coins",
            searchHashtags:"Search hashtags",
            searchDescription: "Enter a keyword to find lists of matching hashtags",
            searchPlaceholder: "For example, a cat",
            pickedTagsTitle: "Selected hashtags",
            tagsCopied: "Hashtags copied",
            notFound: "Nothing found.",
            notFound2: "Try a different search",
            searchHashtags:"Popular hashtags",
            pickedTagsTitle: "Selected hashtags",
            tagsCopied: "Hashtags copied",
            promote:"Promote profile",
            create:"Create",
            order:"order",
            all:"All",
            getCoins:"Get coins",
            viewsTitle: 'Go to TikTok, watch for at least 10 seconds. If you want, like and write a comment ',
            followersTitle: 'Go to TikTok and subscribe to an account.',
            followersTitle2:'DO NOT unfollow.',
            view:"view",
            editOrder:"Edit order",
            newOrder:"New order",
            link:"Video link",
            countViews:"Views count",
            noMoney:"Not enough coins on balance",
            createOrder:"Create order",
            buy:"Buy",
            orderFollowers: "Order Subscribers",
            profileLink: "Profile link",
            countFollowrs: "Number of subscribers",
            addToOrder: "Add to order",
            buyCoins: "Buy Coins",
            thisIs: "this is",
            more: "More suggestions",
            promotion:"Promote profile",
            getCoins:"Get coins",
            email:"Email to contact us",
            buyCoins:"Buy coins",
            searchTags: "Search tags",
            popularTags: "Popular Tags",  
            appeal:"Appeal",
            watch:"Watch",
            follow:"Follow",
            usernamePlaceholder:"TikTok login",
            enterBtn:"Enter",
            followers:"followers",
            added:"added",
            addFollowers:"add followers",
            viewsCount:"Views",
            likesCount:"likes",
            shareCount:"Shared",
            commentsCount:"Comments",
            progress:"Progress",
            createOrderBtn:"Promote",
            buyWrapping:"Order a promotion",
            buyWrappingDesc:"The price depends on the length of the video",
            buyWrappingDesc2:"20 seconds = 2 coins",
            addToViews:"Add To Views",
            plusCoins:"+2 coins",
            likes:"likes",
            comments:"Comments",
            shares:"Share",
            followers:"Follow",
            priceOfOrder:"Order value",
            notEnoughtCoins:"Not enough coins on balance",
            exit:"Exit",
            followersCount:"followers",
            goToVideo : "Go to video",
            goToAuthor : "Go",
            viewTaskDesc : "View video",
            likeTaskDesc : "Like",
            shareTaskDesc : "Share Video",
            shareTaskDesc2 : "Copy link and send to someone in any messenger",
            commentTaskDesc : "Write a suitable positive comment",
            commentTaskDesc2 : "The comment must match the content of the video.",
            followTaskDesc : "Follow the creator of the video",
            reward : "Reward accrued only when all actions are completed",
            authError : "No such name found. Check the spelling of your username",
            taskErrorText : "The data doesn't always update quickly, so our system didn't detect your action. If you actually completed the tasks, then click \"Check again\" or perform the task again",
            taskErrorBtn : "Check again",
        }
    });
    return strings;
};
