import React from 'react';

class ProgressLine extends React.Component {
    constructor(props){
        super(props)
        
        var width = ((this.props.all_coins - (this.props.count_coins < 0 ? 0 : this.props.count_coins))/this.props.all_coins)*100
        if (width >= 100 || (this.props.count_coins != 0 && this.props.count_coins < 2)){
            width = 100
        }
        this.state = {
            width : width
        }
    }
    
    render() {
        var completedCount = (this.props.all_coins - (this.props.count_coins < 0 ? 0 : this.props.count_coins)) / 2
        const allCount = this.props.all_coins / 2
        if(allCount - completedCount < 1){
            completedCount = allCount
        }
        return (
            
            <div>
                <p style={allCount == 0 ? {opacity : 0.5} : {}} className="progress_line_text">{completedCount} / {allCount}</p>
                <div style={{marginBottom : '26px'}} className="progress_line_container">
                    <div className="progress_line_bg"></div>
                    <div style={this.state.width == 100 ? {width : this.state.width + "%", background : 'var(--tiktok_red)'} : {width : this.state.width + "%"}} className="progress_line"></div>
                </div>
            </div>
            
        );
    }
}
export default ProgressLine;
