import React from 'react';

class TagItem extends React.Component {
    constructor(props){
        super(props)
        
    }
    pickedTag = () => {
        this.props.pickedTag(this.props.tag.name, this.props.tag)
    }
    render() {
        return (
            <div onClick={this.pickedTag} className="flex_just tag_item hover">
                <p style={this.props.style ? this.props.style : {}} className="picked_tags_text">{"#" + this.props.tag.name}</p>
                <p style={this.props.style ? this.props.style : {}} className="picked_tags_text">{this.props.tag.formatted_media_count}</p>
            </div>
           
        );
    }
}
export default TagItem;