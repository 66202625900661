import React from 'react';

class LoaderCenter extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div style={this.props.style != undefined ? this.props.style : {}} className="account-content-loader loader-insta progress_bgstyle center_loader">
                    <div className="account-content-loader loader-insta progress_bgstyle">
                        <div className="lds-ring loader-earn-container">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ); 
        
        
    }
}
export default LoaderCenter;
