import React from 'react';
import qs from 'querystring'
import { getRequestParams } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/utils_android/UrlHelperAndroid';
import  arrow  from './icons_android/arrow_left_android.svg';
import  burger  from './icons_android/mobile_bar_android.svg';
import  shop  from './icons_android/shop.svg';
import  ic_coin  from './icons_android/ic_coin.svg';
import { finish } from '../pages_android/custom_views/utils_android/AndroidMethods';
class NavbarAndroid extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isBack : false
        }
        
    }
   
    sideButtonClicked = function(){
        var backUrl = getUrlParameter("back")
        if (this.state.isBack || this.props.isShowOrderInfo){
            if (this.props.isShowOrderInfo){
                if (this.props.isCreateOrder){
                    this.props.toggleCreateOrder()
                } else {
                    this.props.toggleOrderInfo()
                }
                
            } else {
                window.location.href = '/' + backUrl + "?testReactJS=1"
            }
            
        } else {
            var overlay = document.createElement('button');

            overlay.setAttribute('title', 'Закрыть меню');
            overlay.className = 'overlay';
            document.querySelector('.app').appendChild(overlay);
            document.querySelector('.aside').style.left = '0';
            overlay.addEventListener('click', function (e) {

                document.querySelector('.aside').removeAttribute('style');
                document.querySelector('.app').removeChild(overlay);
        
            });
        }
        
    }
    componentDidMount(){
        var backUrl = getUrlParameter("back")
        if (backUrl != undefined && backUrl != ""){
            this.setState({isBack : true})
        }
    }
    closeUpmob = () => {
        finish()
    }
    openShop = () => {
        window.location.href = '/shop'
    }
    render() {
        return (
            <div>
                <header className="header justify_content">
                    {/* <meta name="yandex-verification" content="2dd4a0f15da34a8b" /> */}
                    <div className="flex justify_content width_100">
                            <img onClick={() => this.sideButtonClicked()} src={this.props.isBack || this.props.isShowOrderInfo ? arrow : burger} className={this.state.isBack ? 'header__mobile-menu_back' : 'header__mobile-menu_burger'}/>
                            <div onClick={this.openShop} className="flex">
                                <img  src={ic_coin} alt=""/>
                                <div className="block top_6">
                                    <p className="money_nav_text">{this.props.count_coins}</p>
                                    <p className="money_nav_text">{this.props.declension}</p>
                                </div>
                                <img className="shop_icon" src={shop} alt=""/>
                            </div>
                    </div>
                </header>
                <div className="header_line"></div>
            </div>
            
           
        );
    }
}
export default NavbarAndroid;