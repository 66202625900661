import React from 'react';
import LocalizedStrings from 'react-localization'
import btn_search_tag from '../pages_android/custom_views/custom_icons/btn_search_tag.svg'
import copy from '../pages_android/custom_views/custom_icons/copy.svg'
import check from '../pages_android/custom_views/custom_icons/check.svg'
import not_found from '../pages_android/custom_views/custom_icons/not_found.svg'
import { getHttpParams } from './custom_views/utils_android/Utils';
import Loader from './custom_views/utils_android/LoaderAndroid';
import LoaderCenter from './custom_views/utils_android/LoaderCenter';
import TagItem from './list_items/TagItem';
import { copyId } from './custom_views/utils_android/AndroidMethods';
import CopyCostDialog from './custom_views/CopyCostDialog';
import { getLocalizedStrings } from '../LocalizedStrings';

let strings = getLocalizedStrings()
class SearchTags extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            searchWord : "",
            isShowTags : false,
            isLoading : true,
            isShowError : false,
            pickedTags : "",
            foundTags : [],
            isShowCostCopy : false
        }
        this.searchWordChanged = this.searchWordChanged.bind(this)
        this.pickedTag = this.pickedTag.bind(this)
    }
    
    searchWordChanged(event){
        if (event){
            var searchWord = event.target.value
            this.setState({searchWord : searchWord})
        }
    }
    toogleCostCopy = () => {
        this.setState({isShowCostCopy : !this.state.isShowCostCopy})
    }
    copyTags = () => {
        //copyId(this.state.pickedTags, strings.tagsCopied)
        this.toogleCostCopy()
    }
    searchTags = () => {
        this.setState({isShowTags : true, isLoading : true})
        var params = {}
        params.tag = this.state.searchWord
        fetch("https://www.instarlike.com/api/searchTag", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.status == "OK" && responseData.photos && responseData.photos.results.length > 0){
                this.setState({foundTags : responseData.photos.results, isShowError : false})
            } else {
                this.setState({isShowError : true, foundTags : []})
            }
            this.setState({isLoading : false})
        })
    }
    pickedTag(tag, item){
        var tags = this.state.pickedTags
        if (tags == ""){
            tags = "#" + tag
        } else {
            tags = tags + " #" + tag
        }
        var foundTags = this.state.foundTags
        const index = foundTags.indexOf(item);
        if (index > -1) {
            foundTags.splice(index, 1);
        }
        this.setState({pickedTags : tags, foundTags : foundTags})
    }
    pickAll = () => {
        var pickedTags = ""
        this.state.foundTags.forEach(element => {
            if (pickedTags == ""){
                pickedTags = "#" + element.name
            } else {
                pickedTags = pickedTags + " #" + element.name
            }
        });
        this.setState({pickedTags : pickedTags})
    }
    render() {
        return (
            
            <div className="search_page main_container">
               <p className="title_page">{strings.searchHashtags}</p>
               <p className="search_description">{strings.searchDescription}</p>
               <div className="search_cont">
                    <input onChange={this.searchWordChanged} placeholder={strings.searchPlaceholder} className="search_tag_input" value={this.state.searchWord}/>
                    <img onClick={this.searchTags} className="search_tag_btn hover" src={btn_search_tag}/>
               </div>
               <div className={this.state.isShowTags ? "found_tags_container found_tags_container_show" : "found_tags_container"}>
                    {this.state.isLoading ? <LoaderCenter/> :
                    this.state.foundTags.length == 0 ? 
                    <div>
                        <img className="center_error" src={not_found}/>
                        <p className="center_error_text">{strings.notFound}<br/>{strings.notFound2}</p>
                    </div> :
                    <div className="found_tags_cont">
                        <div onClick={this.copyTags}>
                            <div className="flex_just">
                                <p className="picked_tags_title">{strings.pickedTagsTitle}</p>
                                <img src={copy}/>
                            </div>
                            <p className="picked_tags_text top_m_8">{this.state.pickedTags}</p>
                        </div>
                        
                        <div className="tags_line"/>
                        <img onClick={this.pickAll} className="check_img" src={check}/>
                        <div className="hashtags_list">{this.state.foundTags.length > 0 && this.state.foundTags.map((item, index) => (
                                <TagItem pickedTag={this.pickedTag} tag={item} key={index}/>
                        ))}</div>
                    </div>}
               </div>
               {this.state.isShowCostCopy && <CopyCostDialog count_coins={this.props.count_coins} updateMoney={this.props.updateMoney} pickedTags={this.state.pickedTags} succesText={strings.tagsCopied} cancelCheckToGoogle={this.toogleCostCopy}/>}

            </div>


        );
    }
}
export default SearchTags;