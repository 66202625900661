import React from 'react';
import { getLocalizedStrings } from '../LocalizedStrings';
import ProgressLine from './custom_views/ProgressLine';

let strings = getLocalizedStrings()
class OrderInfo extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
           
        }
       
    }
    
    
    render() {
        const order = this.props.order
        return (
            
            <div className="search_page main_container">
               <img className="order_info_img center_all" src={this.props.order.video.originCover}/>
               <div className="flex space_between">
                   <div className="info_column">
                        <p className="info_title">{strings.viewsCount}<br/>{this.props.order.stats.playCount}</p>
                        <p className="info_text">{strings.progress}</p>
                        <ProgressLine all_coins={order.views_task ? order.views_task.all_coins : 0} count_coins={order.views_task ? order.views_task.count_coins : 0}/> 

                        <p style={{marginTop : '32px'}} className="info_title">{strings.shareCount}<br/>{this.props.order.stats.shareCount}</p>
                        <p className="info_text">{strings.progress}</p>
                        <ProgressLine all_coins={order.shares_task ? order.shares_task.all_coins : 0} count_coins={order.shares_task ? order.shares_task.count_coins : 0}/>
                   </div>
                   <div className="info_column">
                        <p className="info_title">{strings.likesCount}<br/>{this.props.order.stats.diggCount}</p>
                        <p className="info_text">{strings.progress}</p>
                        <ProgressLine all_coins={order.likes_task ? order.likes_task.all_coins : 0} count_coins={order.likes_task ? order.likes_task.count_coins : 0}/> 
                         

                        <p style={{marginTop : '32px'}} className="info_title">{strings.commentsCount}<br/>{this.props.order.stats.commentCount}</p>
                        <p className="info_text">{strings.progress}</p>
                        <ProgressLine all_coins={order.comments_task ? order.comments_task.all_coins : 0} count_coins={order.comments_task ? order.comments_task.count_coins : 0}/> 
                   </div>
               </div>

               <div onClick={this.props.createOrder} className="relative enter_btn">
                    <div className="btn-follow top_10">
                        {strings.createOrderBtn}
                    </div>
                </div>
            </div>


        );
    }
}
export default OrderInfo;