import React from 'react';
import x_close_bottom from './custom_icons/x_close_bottom.svg'
import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    ru:{
        title:"Заказать",
        views:"Просмотры",
        followers:"Подписчиков",
        
      },
      en: {
        title:"Create order",
        views:"Views",
        followers:"Followers",
      }
   });
class TypeTaskSheet extends React.Component {
    constructor(props){
        super(props)
    }
    onPicked = (type) => {
        this.props.toggleBottomSheet()
        this.props.onPicked(type)
    } 
    render() {
        return (
            <div className={this.props.isShown ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden'}>
                <div onClick={this.props.toggleBottomSheet} className={this.props.isShown ? 'animation_bg animateAppealBgShow' : 'animation_bg animateAppealBgHide'}>

                </div>
                <div className={this.props.isShown ? 'appeal_container_shown appeal_container_style' : 'appeal_container_hide appeal_container_style'}>
                    <div className="block padding_16 relative">
                        <p className="task_name appeal_text_task_money size_22">{strings.title}</p>
                        <img onClick={this.hideBottomSheet} className="x_close_bottom" src={x_close_bottom} />
                        <p onClick={() => this.onPicked(0)} className="task_name appeal_text_task_money">{strings.views}</p>
                        <p onClick={() => this.onPicked(1)}  className="task_name appeal_text_task_money bottom_64">{strings.followers}</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default TypeTaskSheet;