import React from 'react';
import x_close from './tasks_icons/x_close_bottom.svg'

import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    ru:{
        notFair:"Так нечестно!",
        youWatched1:"Ты смотрел ролик",
        youWatched2:"менее 10 сек.",
        youWatched3:"Чтобы просмотр засчитался, и ты получил монеты, вернись в TikTok и посмотри ролик подольше.",
        youWatched4:"Досмотреть",
        
      },
      en: {
        notFair: "So dishonest!",
         youWatched1: "You watched the video",
         youWatched2: "less than 10 seconds.",
         youWatched3: "In order for the view to count and you get coins, return to TikTok and watch the video for a longer time.",
         youWatched4: "Watch",
      }
   });
class ErrorBanner extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                            <img onClick={() => this.props.closeBannerClicked()} className="close_icon_error" src={x_close}
                                alt=""/>
                            <p className="error_title">{strings.notFair}</p>
                            <p className="error_text">{strings.youWatched1}<strong> {strings.youWatched2} </strong><br/><br/>{strings.youWatched3}</p>
                            <p onClick={() => this.props.successClicked()} className="error_btn">{strings.youWatched4}</p>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ErrorBanner;