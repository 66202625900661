import React from 'react';
import close from '../../icons_android/x_android.svg'
import BtnLoader from './BtnLoader';

class ErrorDialog extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_task">
                            <img onClick={() => this.props.closeBannerClicked()} className="close_icon_error" src={close}
                                alt=""/>
                            <p className="text_error_b">{this.props.errorTitle}</p>
                            <div className="flex relative">
                                <p style={this.props.isLoading ? {opacity : 0, pointerEvents : 'none'} : {}} onClick={() => this.props.successClicked()} className="error_btn">{this.props.errorBtn}</p>
                                {this.props.isLoading && <BtnLoader style={{padding : '16px'}}/>}

                            </div>
                    </div>  
                </div>
            ); 
        
        
    }
}
export default ErrorDialog;