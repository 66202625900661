export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = window.document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };
export const setCookie = (name,value) => {
    var expires = "";
    var days = 9999
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    window.document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const getRequestParams = () => {
     
        var requestParams = {
            'token': getToken(),
            'user_id': getToken(),
            'old_token': getCookie("old_token"),
            'uniqueId': getCookie("uniqueId"),
            'nickname': getCookie("nickname"),
            'avatarLarger': getCookie("avatarLarger"),
            'bundle_app' : 'com.hashtags.promotok'
        }
        
        // var requestParams = {
        //     'token': "68::fb424:8e.7f99.cg55.d8bc.c6d4249f",
        //     'user_id': "68::fb424:8e.7f99.cg55.d8bc.c6d4249f",
        //     'bundle_app' : 'com.hashtags.promotok'
        // }
       
        return requestParams;
    };
    export const setIsTimestamped = () => {
        setCookie('isTimestamped', 'isTimestamped')
    };
    export const getIsTimestamped = () => {
        return getCookie("isTimestamped");
    };
    export const setCoinsBought = (id) => {
        setCookie(id, id)
    };
    export const getCoinsBought = (id) => {
        return getCookie(id);
    };
    export const saveRegistrCookie = (token) => {
        setCookie('token', token)
        setCookie('user_id', token)
    };
    export const setShownCheckToGoogle = () => {
        setCookie('isShowCheckToGoogle', 'true')  
    };
    export const isShownCheckToGoogle = () => {
        return getCookie("isShowCheckToGoogle");
    };
    
    export const getRequestUrl = () => {
        return "https://tik2top.com/api/";
    };
    export const getToken = () => {
        return getCookie("token");
    };

    export const saveNewCookies = (info) => {
        const token = getToken()
        if (token != undefined && token != null && token != ""){
            setCookie('old_token', token)
        }
        setCookie('token', info.id)
        setCookie('user_id', info.id)
        setCookie('uniqueId', info.uniqueId)
        setCookie('nickname', info.nickname)
        setCookie('avatarLarger', info.avatarLarger)
        setCookie('followerCount', info.stats.followerCount)

    };

// запрос getInfoUser
// info : {id,uniqueId,nickname,avatarLarger, stats : {followerCount}}

// запрос getUserFeed
// [массив видосов, буду называть их order, но это просто объект]
// такие поля юзаются
// order.video.originCover
// order.video.id

// order.stats.playCount
// order.stats.shareCount
// order.stats.diggCount - это лайки
// order.stats.commentCount




    
