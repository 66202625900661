import React from 'react';
import qs from 'querystring'
import { getRequestUrl, getRequestParams } from './custom_views/utils_android/CookieHelper';
import BaseEarnTask from './custom_views/earn_tasks/BaseEarnTask';
import PhotoTask from './custom_views/earn_tasks/PhotoTask';
import help_circle from './icons_android/help_circle.svg'
import ErrorBanner from './custom_views/earn_tasks/ErrorBanner';
import EmptyTask from './custom_views/earn_tasks/EmptyTask';
import LocalizedStrings from 'react-localization'
import { getLocalizedStrings } from '../LocalizedStrings';
import { getHttpParams } from './custom_views/utils_android/Utils';

let strings = getLocalizedStrings()
class Earn extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isLoading : true,
            currentExtraOrder : {},
            currentPhotoTask : {},
            isCurrentExtra : true,
            currentType : -1,
            isShowError : false,
            isShowEmpty : false
        }
    }
    componentDidMount(){
        this.getPhotoTask()
    }
    showLoader = () => {
        this.setState({isLoading : true})
    }
    hideLoader = () => {
        this.setState({isLoading : false})
    }
    loadNextOrder = () => {
        this.getPhotoTask()
        console.log("loadNextOrder")
    }
    closeBannerClicked = () => {
        
    }
    successClicked = () => {

    }
    showErrorBanner = (onClick) =>{
        this.successClicked = () => {
            if (onClick != undefined){
                onClick()
            }
            this.setState({isShowError : false})
        }
        this.setState({isShowError : true})
    }
    getPhotoTask = () => {
        this.showLoader();
        var params = getRequestParams();
        params.timestamp = this.props.timestamp
        console.log("getPhotoTask params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "getPhotoTask", getHttpParams(params)).then((response) => response.json()).then((responseData) => {
            if (responseData.photo) {
                this.setState({currentPhotoTask : responseData, isCurrentExtra: false, currentDescription : this.LIKE_PHOTO_TASK_DESC, currentType : 3, isShowEmpty : false})
            } else {
                this.showNoTasksView();
            }
            this.props.setMoney(responseData.user_coins)
            this.setState({isLoading : false})
            console.log("getPhotoTask = " + JSON.stringify(responseData));
        })         
    }
    showNoTasksView = () => {
        console.log("showNoTasksView");
        this.setState({isShowEmpty : true, isLoading : false})
    }
    render() {
        return (
            <section className="earn-money_section main_container">
                {this.state.isShowError && <ErrorBanner closeBannerClicked={this.closeBannerClicked} successClicked={this.successClicked}/>}
                {/* <ErrorBanner closeBannerClicked={this.closeBannerClicked} successClicked={this.successClicked}/> */}

                <div className="relative">
                    <p className="title_page bot_16">{strings.getCoins}</p>
                    
                </div>
                
                {this.state.currentPhotoTask.photo  && <PhotoTask order={this.state.currentPhotoTask.photo} showErrorBanner={this.showErrorBanner} isLoading={this.state.isLoading} timestamp={this.props.timestamp} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} />}
                {this.state.isShowEmpty && <EmptyTask loadNextOrder={this.loadNextOrder}/>}
            </section>
        );
    }
}
export default Earn;