import React from 'react';

class ProgressLineCreate extends React.Component {
    constructor(props){
        super(props)
        var width = ((this.props.all_coins - this.props.count_coins)/this.props.all_coins)*100
        if (width >= 100){
            width = 100
        }
        this.state = {
            width : width
        }
    }
    
    render() {
        return (
            <div className="progress_line_container">
                  <div className="progress_line_bg progress_line_bg_create"></div>
                  <div style={this.state.width == 100 ? {width : this.state.width + "%", background : 'var(--tiktok_red)'} : {width : this.state.width + "%"}} className="progress_line progress_line_create"></div>
            </div>
        );
    }
}
export default ProgressLineCreate;
