import React from 'react';

import LocalizedStrings from 'react-localization'
import { showAndroidToast, openUrl } from './utils_android/AndroidMethods';
import like from '../icons_android/like.svg'
import dislike from '../icons_android/dislike.svg'
let strings = new LocalizedStrings({
    ru:{
        howYouAssess:"Как тебе это приложение?",
        bad:"Не нравится",
        good:"Нравится",
        willBeGlad:"Будем рады уведить Ваш отзыв в Google Play",
       
        
      },
      en: {
        howYouAssess: "How do you like this application?",
         bad: "Dislike",
         good: "Like",
         willBeGlad: "We’ll be happy to get your feedback on Google Play"
        
      }
   });
class CheckToGoogle extends React.Component {
    constructor(props){
        super(props)

    }
    successClicked = () => {
        showAndroidToast(strings.willBeGlad)
        this.props.cancelCheckToGoogle()
        openUrl("https://play.google.com/store/apps/details?id=com.hashtags.promotok")
        
    }
    
    
    render(){
      
            return (
                <div className="error_bg">
                    <div className="error_banner_container">
                            <p className="title_check">{strings.howYouAssess}</p>
                            <div className="check_btns">
                                 <div onClick={this.successClicked} className="ok_btn">
                                     <img className="img_like" src={like}/>
                                    <p className="like_title">{strings.good}</p>
                                 </div>

                                 <div onClick={() => this.props.cancelCheckToGoogle()} className="cancel_container">
                                     <img src={dislike}/>
                                    <p className="dislike_title">{strings.bad}</p>
                                 </div>
                                
                            </div>
                            
                    </div>  
                </div>
            ); 
        
        
    }
}
export default CheckToGoogle;