import React from 'react';

class Loader extends React.Component {
    constructor(props){
        super(props)

    }
    
    render(){
      
            return (
                <div className="account-content-loader loader-insta progress_bgstyle">
                    <div className="account-content-loader loader-insta progress_bgstyle">
                        <div className="lds-ring loader-earn-container">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ); 
        
        
    }
}
export default Loader;
