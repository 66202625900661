import React from 'react';
import qs from 'querystring'
import {getRequestParams} from '../utils_android/CookieHelper'
import { getRequestUrl } from '../utils_android/CookieHelper';
import { showAndroidToast } from '../utils_android/AndroidMethods';
class BaseEarnTask extends React.Component {
    constructor(props){
        super(props)
        this.LIKE_PHOTO_TASK_DESC = 'Перейди в TikTok, посмотри минимум 10 сек. При желании поставь лайк и напиши комментарий'
        
        this.state = {
            extraOrdersCount : this.props.extraOrdersCount
        }
    }
    componentDidMount(prevProps){
        if (prevProps != this.props){
            this.setState({extraOrdersCount : this.props.extraOrdersCount})
        }
    }
    instaLike = (grade) => {
        var params = getRequestParams();
        params.timestamp = this.props.timestamp
        params.photo_id = this.props.currentPhotoTask.photo.photo_id;
        params.grade = grade;
        params._id = this.props.currentPhotoTask.photo._id;
        fetch(getRequestUrl() + "addGrade", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
            } else {
                this.errorTask()
            }
            console.log("instaLike" + JSON.stringify(responseData))
        })
    }
    skipOrder = (isSkipedAuto, id) =>{
        this.props.showLoader()
        
    }
    
    appealClicked = () =>{
        showAndroidToast("Жалоба отправлена")
    }
    errorTask(){
        this.props.hideLoader()
        showAndroidToast("Ошибка проверки выполнения задания. Попробуйте еще раз.")
    }
    openUrl(url) {
        // window.open(url)
        // return
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.openUrl(url);
        } else {
            return false
        }
    }
    getSource = (photo) => {
        if (photo == undefined) {
            return "";
        }
        switch (photo.media_type) {
            case 8:
                return photo.carousel_media[0].image_versions2.candidates[0].url;
            case 1:
                return photo.image_versions2.candidates[0].url;
            case 2:
                return photo.image_versions2.candidates[0].url;
            default:
                return "";
        }
    } 
}
export default BaseEarnTask;