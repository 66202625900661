import React from 'react';
import no_tasks from './tasks_icons/no_tasks.svg';
import './css/tasks.css'; 
import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    ru:{
        getCoins:"Получить монеты",
        noTasks:"Новых заданий пока нет",
        update:"Обновить",
        
      },
      en: {
          getCoins:"Get coins",
          noTasks: "No new tasks yet",
          update: "Update",
          
      }
   });
class EmptyTask extends React.Component {
    constructor(props){
        super(props)
        
    }
  
    updateOrders = () => {
        this.props.loadNextOrder()
    }

    auth = () => {
        window.location.href = "/auth_insta?back=tasks&testReactJS=1"
    }
    render() {
        return (
            <div className="empty_tasks_container empty_container_insta">
                <p className="title_page bot_16">{strings.getCoins}</p>
                <div>
                    <img className="no_tasks" src={no_tasks} alt=""/>
                    <p className="empty_tasks_text">{strings.noTasks}</p>
                    <div onClick={this.updateOrders} className="btn-follow btn_update">{strings.update}</div>
                </div>
            </div>
        );
    }
}
export default EmptyTask;