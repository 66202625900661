import React from 'react';
import LocalizedStrings from 'react-localization'
import btn_search_tag from '../pages_android/custom_views/custom_icons/btn_search_tag.svg'
import copy from '../pages_android/custom_views/custom_icons/copy.svg'
import check from '../pages_android/custom_views/custom_icons/check.svg'
import not_found from '../pages_android/custom_views/custom_icons/not_found.svg'
import { getHttpParams, getPopularTags } from './custom_views/utils_android/Utils';
import Loader from './custom_views/utils_android/LoaderAndroid';
import LoaderCenter from './custom_views/utils_android/LoaderCenter';
import TagItem from './list_items/TagItem';
import { copyId } from './custom_views/utils_android/AndroidMethods';
import CopyCostDialog from './custom_views/CopyCostDialog';
import { getLocalizedStrings } from '../LocalizedStrings';

let strings = getLocalizedStrings()
class PopularTags extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            pickedTags : "",
            hashtags : getPopularTags(),
            isShowCostCopy : false
        }
        this.pickedTag = this.pickedTag.bind(this)
    }
    copyTags = () => {
        //copyId(this.state.pickedTags, strings.tagsCopied)
        this.toogleCostCopy()
    }
    pickedTag(tag, item){
        var tags = this.state.pickedTags
        if (tags == ""){
            tags = "#" + tag
        } else {
            tags = tags + " #" + tag
        }
        var foundTags = this.state.hashtags
        const index = foundTags.indexOf(item);
        if (index > -1) {
            foundTags.splice(index, 1);
        }
        this.setState({pickedTags : tags, hashtags : foundTags})
    }
    pickAll = () => {
        var pickedTags = ""
        this.state.hashtags.forEach(element => {
            if (pickedTags == ""){
                pickedTags = "#" + element.name
            } else {
                pickedTags = pickedTags + " #" + element.name
            }
        });
        this.setState({pickedTags : pickedTags})
    }
    toogleCostCopy = () => {
        this.setState({isShowCostCopy : !this.state.isShowCostCopy})
    }
    render() {
        return (
            
            <div className="search_page main_container">
               <p className="title_page">{strings.searchHashtags}</p>              
               <div className="popular_hastags_container">
                    <div className="found_tags_cont">
                        <div onClick={this.copyTags}>
                            <div className="flex_just">
                                <p className="picked_tags_title">{strings.pickedTagsTitle}</p>
                                <img src={copy}/>
                            </div>
                            <p className="picked_tags_text top_m_8 text_color_white">{this.state.pickedTags}</p>
                        </div>
                        
                        <div className="tags_line"/>
                        <img onClick={this.pickAll} className="check_img" src={check}/>
                        <div className="hashtags_list">{this.state.hashtags.length > 0 && this.state.hashtags.map((item, index) => (
                                <TagItem style={{color : "white"}} pickedTag={this.pickedTag} tag={item} key={index}/>
                        ))}</div>
                    </div>
               </div>
               {this.state.isShowCostCopy && <CopyCostDialog count_coins={this.props.count_coins} updateMoney={this.props.updateMoney} pickedTags={this.state.pickedTags} succesText={strings.tagsCopied} cancelCheckToGoogle={this.toogleCostCopy}/>}
            </div>


        );
    }
}
export default PopularTags;