import React from 'react';
import no_tasks from './tasks_icons/no_tasks.svg';
import './css/tasks.css'; 

class BtnLoader extends React.Component {
    constructor(props){
        super(props)
        
    }
  
   
    render() {
        return (
            <div style={this.props.style != undefined ? this.props.style : {}} className="btn-loader loader_task">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }
}
export default BtnLoader;
                        