import React from 'react';
import x_close_bottom from './custom_icons/x_close_bottom.svg'
import { getLocalizedStrings } from '../../LocalizedStrings';
import { getCookie, getRequestUrl, getRequestParams } from './utils_android/CookieHelper';
import { declension, getHttpParams } from './utils_android/Utils';
import BtnLoader from './earn_tasks/BtnLoader';
var strings = getLocalizedStrings()
class BuyFollowersSheet extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            followersCount : 100,
            priceCount : 200,
            isLoading : false,
            isShowBuyCoins : false
        }
    }
    onPicked = (type) => {
        this.props.toggleBottomSheet()
        this.props.onPicked(type)
    } 
    onCountChanged = (event) => {
        if (event){
            var count = event.target.value
            this.setState({followersCount : count, priceCount : count*2})
        }
        
    }
    createOrder = () => {
        console.log("createOrder")
        
        if (this.state.priceCount > this.props.count_coins){
            this.setState({isShowBuyCoins : true, isLoading : false})
            return
        } else {
            this.setState({isShowBuyCoins : false, isLoading : false})
        }
        this.setState({isLoading : true})
        var params = getRequestParams()
        params.photo_path_standart = getCookie("avatarLarger")
        params.photo_path_low = getCookie("avatarLarger")
        params.is_active = 1;
        params.photo_id = getCookie("user_id")
        params.media_id = getCookie("user_id")
        params.media_url = "https://www.tiktok.com/@" + getCookie("uniqueId")
        params.add_coins = this.state.priceCount
        params.is_photo = false
        params.type = 1
        console.log("setPhotoState params" + JSON.stringify(params))
        fetch(getRequestUrl() + "setPhotoState", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == "1" || responseData.response == "2"){
                this.props.updateMoney(-this.state.priceCount)
                // window.location.href = '/orders?rand=' + Math.random()
            }
            this.setState({isLoading : false})
            console.log("setPhotoState" + JSON.stringify(responseData))
            
        })
    }
    buyCoins = () => {
        window.location.href = '/shop'
    }
    render() {
        return (
            <div className={this.props.isShown ? 'appeal_container_bg_shown' : 'appeal_container_bg_hiden'}>
                <div onClick={this.props.toggleBottomSheet} className={this.props.isShown ? 'animation_bg animateAppealBgShow' : 'animation_bg animateAppealBgHide'}></div>
                <div className={this.props.isShown ? 'appeal_container_shown appeal_container_style' : 'appeal_container_hide appeal_container_style'}>
                    <div className="block padding_16 relative">
                        <img style={{marginTop : '56px'}} className="avatar_img center_img" src={getCookie("avatarLarger")}/>
                        <p className="create_order_t">{strings.buyWrapping}</p>
                        <p style={{marginTop : '32px'}} className="create_order_count_t">{strings.countFollowrs}</p>
                        <div style={{marginBottom : '32px'}} className="create_order_count_in relative center_all">
                            <input onChange={this.onCountChanged} value={this.state.followersCount} className="create_order_count_input"/>
                        </div>

                        <div className="create_order_btn_n">
                            <p className="create_order_summ">{strings.priceOfOrder}<span style={{fontWeight : '700'}}> {this.state.priceCount} {declension(this.state.priceCount, strings.coins_1, strings.coins_2, strings.coins_5)}</span></p>
                            <div onClick={this.createOrder} style={this.state.isLoading ? {background : 'black', color : 'white', pointerEvents : 'none'} : {background : 'black', color : 'white'}} className="btn-follow top_10">
                                {this.state.isLoading ? <BtnLoader style={{marginTop : '-4px'}}/> : strings.createOrderBtn}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isShowBuyCoins && <div onClick={this.buyCoins} className="not_enought_container">
                    <p className="not_enought_text">{strings.notEnoughtCoins}</p>
                    <p style={{fontWeight : '700'}} className="not_enought_text">{strings.buy}</p>
                </div>}
            </div>
        );
    }
}
export default BuyFollowersSheet;