import React from 'react';
import empty_image_insta from './tasks_icons/empty_image_insta.svg'
import chevron_right from './tasks_icons/chevron_right.svg'
import BaseEarnTask from './BaseEarnTask';
import eyes_icon from '../custom_icons/eyes.svg'
import play_task from '../custom_icons/play_task.svg'
import share_task from '../custom_icons/share_task.svg'
import user_task from '../custom_icons/user_task.svg'
import message_task from '../custom_icons/message_task.svg'
import heart_task from '../custom_icons/heart_task.svg'
import plus from '../custom_icons/plus.svg'
import './css/tasks.css'; 

import { getLocalizedStrings } from '../../../LocalizedStrings';
import { getRequestParams, getRequestUrl } from '../utils_android/CookieHelper';
import { getHttpParams } from '../utils_android/Utils';
import ErrorDialog from './ErrorDialog';
const strings = getLocalizedStrings()

class PhotoTask extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            isShowError : false,
            isLoading : false
        }
        this.startTaskTime = -1
        window.photoTask = this
    }
    onResume(){
        if (this.startTaskTime != -1){
            this.props.showLoader()
            var currentTime = new Date().getTime() / 1000
            console.log("onResume currentTime = " + currentTime)
            console.log("onResume this.startTaskTime = " + this.startTaskTime)
            console.log("onResume result = " + (parseInt(currentTime) - 1 >= parseInt(this.startTaskTime)))
            if (parseInt(currentTime) - 1 >= parseInt(this.startTaskTime)){
                this.checkAndExecuteTask()
                this.startTaskTime = -1
            } else {
                this.startTaskTime = -1
                this.props.showErrorBanner(this.doTask)
            }
            
        }
        
    }
    doTask = () => {
        this.startTaskTime = new Date().getTime() / 1000
        console.log("doTask this.startTaskTime = " + this.startTaskTime)
        this.prepareTask()
        this.openUrl(this.props.currentPhotoTask.photo.media_url)
    }
    
    skip = () => {
        this.instaLike(0)
        //this.skipOrder(false, this.props.currentPhotoTask.photo._id)
    }
    prepareTask = () => {
        var params = getRequestParams();
        params.task_id = this.props.order._id
        fetch(getRequestUrl() + "prepareTask", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            
            console.log("prepareTask" + JSON.stringify(responseData))
        })
    }
    checkAndExecuteTask = () => {
        if (this.state.isLoading){
            return
        }
        this.setState({isLoading : true})
        var params = getRequestParams();
        params.task_id = this.props.order._id
        fetch(getRequestUrl() + "checkAndExecuteTask", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
                this.setState({isShowError : false})
            } else {
                //this.errorTask()
                this.setState({isShowError : true})
            }
            this.setState({isLoading : false})
            console.log("checkAndExecuteTask" + JSON.stringify(responseData))
        })
    }
    getInfoByType = () => {
        var info = {}
        const type = this.props.order.type
        switch (type) {
            case 0:
                info.btn = strings.goToVideo
                info.description = strings.viewTaskDesc
                info.icon = play_task
                break;
            case 1:
                info.btn = strings.goToAuthor
                info.description = strings.followTaskDesc
                info.icon = user_task
                break;
            case 2:
                info.btn = strings.goToVideo
                info.description = strings.likeTaskDesc
                info.icon = heart_task
                break;
            case 3:
                info.btn = strings.goToVideo
                info.description = strings.commentTaskDesc
                info.description_2 = strings.commentTaskDesc2
                info.icon = message_task
                break;
            case 4:
                info.btn = strings.goToVideo
                info.description = strings.shareTaskDesc
                info.description_2 = strings.shareTaskDesc2
                info.icon = share_task
                break;
            default:
                break;
        }
        return info
    }
    checkAgain = () => {
        this.checkAndExecuteTask()
    }
    closeBannerClicked = () => {
        this.setState({isShowError : false, isLoading : false})
    }
    render() {
        const orderInfo = this.getInfoByType()
        return (
            <div className="rate-content__item">
                    <div className="photo_task_container">
                        <div className={this.props.isLoading ? "not_display" : "flex"} >
                            {/* <p onClick={this.checkAndExecuteTask}>Test task</p> */}
                            <div className="order_photo task_photo">
                                <img className={this.props.order.type == 1 ? "avatar_img center_img" : "task_img_video center_img"} src={this.props.order.photo_path_standart}/>
                                {/* <img src={this.props.order.photo_path_standart} className="center_img"/> */}
                            </div>

                        </div>
                        <img onClick={this.skip} src={chevron_right} className="arrows__item arrows__item_next arrow_item_follower"></img>
                        
                        <div className={this.props.isLoading ? "task_loader_bg" : "not_display"}>
                            <div className="lds-ring loader-earn-container loader_task">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        
                    </div>
                    {/* <img className="plus_icon center_img" src={plus}/> */}
                    <div onClick={this.doTask} style={{borderRight : 'white', marginBottom : '24px', marginTop : '24px', height : 'auto', textAlign : 'left'}} className="btn-follow top_10 flex">
                        <img className="desc_icon" src={orderInfo.icon}/>
                        <div>
                            <p className="task_desc">{orderInfo.description}</p>
                            {orderInfo.description_2 != undefined && <p className="task_desc2">{orderInfo.description_2}</p>}
                        </div>
                        
                    </div>
                    <div className="rate-content__actions">
                        <div className="relative">
                            <div onClick={this.doTask} className="btn-follow top_10">{orderInfo.btn}</div>
                            {/* <div className="plus_coins_plashka">+{this.props.currentPhotoTask.earn_money}</div> */}
                            <div className="plus_coins_plashka">+1</div>
                        </div>
                        
                    </div>
                    <p className="reward_desc">{strings.reward}</p>
                    {this.state.isShowError && <ErrorDialog isLoading={this.state.isLoading} closeBannerClicked={this.closeBannerClicked} successClicked={this.checkAgain} errorBtn={strings.taskErrorBtn} errorTitle={strings.taskErrorText}/>}

            </div>
        );
    }
}
export default PhotoTask;
