import React from 'react';
import qs from 'querystring'
import { getRequestParams, getRequestUrl, getCoinsBought, setCoinsBought } from '../pages_android/custom_views/utils_android/CookieHelper';
import { getUrlParameter } from '../pages_android/custom_views/utils_android/UrlHelperAndroid';
import play from './icons_android/play_buy.svg'
import { declension, getHttpParams } from './custom_views/utils_android/Utils';
import { buyCoinsAndroid, initBilling, openUrl } from './custom_views/utils_android/AndroidMethods';
import LocalizedStrings from 'react-localization'
import { getLocalizedStrings } from '../LocalizedStrings';
let strings = getLocalizedStrings()
class BuyCoins extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            coinsCount : 100,
            isLoading : false,
            coinsDeclension : strings.coins_5,
            isBought100 : false,
            isBought500 : false,
            isBought1000 : false,
            isBought5000 : false,
            isBought10000 : false,
            isBought50000 : false,
        }
        window.shop = this
        this.payUrl = ""
        
    }
    onCountChanged = (event) => {
        if (event){
            var count = event.target.value
            this.setState({coinsCount : count, coinsDeclension : declension(count, strings.coins_1, strings.coins_2, strings.coins_5)})
        }
    }
    buyCoins = (count) => {
        if (this.payUrl == ""){
            buyCoinsAndroid("tiktok_" + count)
        } else {
            const url = this.payUrl + "&tiktok_coins=" + count
            openUrl(url)
            console.log("buyCoins url = " + url)

        }
        
        console.log("buyCoins = " + ("tiktok_" + count))
    }
    coinsPurchased(details){
        console.log("coinsPurchased details = " + JSON.stringify(details))
        var params = getRequestParams()
        params.info = JSON.stringify(details)
        console.log("coinsPurchased params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "addPurchaseCoins", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.count_coins){
                this.props.setMoney(responseData.count_coins)
                this.coinsPurchasedUpdateButtons(details.info.data.productId.split("_")[1])
            }
            console.log("addPurchaseCoins" + JSON.stringify(responseData))
        })
    }
    getMyCoins = () => {
        var params = getRequestParams()
        console.log("params getMyInfo = " + JSON.stringify(params))
        fetch(getRequestUrl() + "getMyCoins", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            this.props.setMoney(responseData.count_coins)
            if (responseData.url){
                this.payUrl = responseData.url
            }
            console.log("getMyCoins" + JSON.stringify(responseData))
        })
    }
    coinsPurchasedUpdateButtons(count){
        switch (count + ""){
            case "100" :
                this.setState({isBought100 : true})
                setCoinsBought("100")
                break
            case "500" :
                this.setState({isBought500 : true})
                setCoinsBought("500")
                break
            case "1000" :
                this.setState({isBought1000 : true})
                setCoinsBought("1000")
                break
            case "5000" :
                this.setState({isBought5000 : true})
                setCoinsBought("5000")
                break
            case "10000" :
                this.setState({isBought10000 : true})
                setCoinsBought("10000")
                break
            case "50000" :
                this.setState({isBought50000 : true})
                setCoinsBought("50000")
                break
        }
        
    }
    coinsPurchasedUpdate(details){
        // switch (count + ""){
        //     case "100" :
        //         if (getCoinsBought("100") != "100"){
        //             this.buyCoins(100)
        //             setCoinsBought("100")
        //         }
        //         this.setState({isBought100 : true})
        //         break
        //     case "500" :
        //         if (getCoinsBought("500") != "500"){
        //             this.buyCoins(500)
        //             setCoinsBought("500")
        //         }
        //         this.setState({isBought500 : true})
        //         break
        //     case "1000" :
        //         if (getCoinsBought("1000") != "1000"){
        //             this.buyCoins(1000)
        //             setCoinsBought("1000")
        //         }
        //         this.setState({isBought1000 : true})
        //         break
        //     case "5000" :
        //         if (getCoinsBought("5000") != "5000"){
        //             setCoinsBought("5000")
        //             this.buyCoins(5000)
        //         }
        //         this.setState({isBought5000 : true})
        //         break
        //     case "10000" :
        //         if (getCoinsBought("10000") != "10000"){
        //             setCoinsBought("10000")
        //             this.buyCoins(10000)
        //         }
        //         this.setState({isBought10000 : true})
        //         break
        //     case "50000" :
        //         if (getCoinsBought("50000") != "50000"){
        //             setCoinsBought("50000")
        //             this.buyCoins(50000)
        //         }
        //         this.setState({isBought50000 : true})
        //         break
        // }
        this.coinsPurchased(details)
    }
    componentDidMount(){
        initBilling()
        this.getMyCoins()
    }
    onResume(){
        initBilling()
        this.getMyCoins()
    }
    render() {
        return (
            <div className="relative main_container">
               <p className="title_page">{strings.buyCoins}</p>
                <div className="flex buy_container">
                    <div style={this.state.isBought5000 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(5000)} className="buy_plashka left_right_plashka left_plashka">
                        <p className="buy_plashka_title">5 000<br/>{strings.coins_5}</p>
                        <div className="flex desc_container_buy">
                            <p className="buy_plashka_desc">{strings.thisIs} 2 500</p>
                            <img src={play}/>
                        </div>
                        <div className="flex desc_container_buy top_16">
                            {/* <p className="buy_plashka_desc decoration_line">200₽</p> */}
                            <p className="buy_plashka_desc price_buy">-20%</p>
                        </div>
                        <p className="buy_plashka_title btn_buy_left">{strings.buy}</p>
                        
                        
                    </div>
                    <div style={this.state.isBought10000 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(10000)} className="buy_plashka center_plashka">
                        <p className="buy_plashka_title">10 000<br/>{strings.coins_5}</p>
                            <div className="flex desc_container_buy">
                                <p className="buy_plashka_desc">{strings.thisIs} 5 000</p>
                                <img src={play}/>
                            </div>
                            <div className="flex desc_container_buy top_16">
                                {/* <p className="buy_plashka_desc decoration_line">200₽</p> */}
                                <p className="buy_plashka_desc price_buy">-30%</p>
                            </div>
                        <div className="buy_plashka_title btn_buy btn_buy_absolut">{strings.buy}</div>
                    
                    </div>

                    <div style={this.state.isBought50000 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(50000)} className="buy_plashka left_right_plashka right_plashka">
                        <p className="buy_plashka_title">50 000<br/>{strings.coins_5}</p>
                            <div className="flex desc_container_buy">
                                <p className="buy_plashka_desc">{strings.thisIs} 25 000</p>
                                <img src={play}/>
                            </div>
                            <div className="flex desc_container_buy top_16">
                                {/* <p className="buy_plashka_desc decoration_line">200₽</p> */}
                                <p className="buy_plashka_desc price_buy">-40%</p>
                            </div>
                            <p className="buy_plashka_title btn_buy_left">{strings.buy}</p>
                    </div>
                </div>

                <p className="buy_text_another">{strings.more}</p>
                
                {/* <p className="buy_text_another">Или купи любое количество монет</p>
                <div className="flex buy_input_container">
                    <div style={this.state.coinsCount > 0 ? {border : '2px solid #98AFB7'} : {border : '2px solid var(--tiktok_red)'}} className="input_buy relative">
                        <input onChange={this.onCountChanged} value={this.state.coinsCount} className="input_buy_another"/>
                        <p className="input_coins_buy">{this.state.coinsDeclension}</p>
                    </div>
                    <div className="buy_plashka_title btn_buy btn_buy_full">Купить</div>
                </div> */}
                <div style={this.state.isBought100 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(100)} className="buy_coins_container_shop">
                    <p className="buy_coins_text_shop">100 {strings.coins_5}</p>
                    <p className="buy_coins_text_shop buy_coins_text_bold_shop">{strings.buy}</p>
                </div>

                <div style={this.state.isBought500 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(500)} className="buy_coins_container_shop">
                    <p className="buy_coins_text_shop">500 {strings.coins_5}</p>
                    <p className="buy_coins_text_shop buy_coins_text_bold_shop">{strings.buy}</p>
                </div>

                <div style={this.state.isBought1000 ? {opacity : 0.2} : {opacity : 1, pointerEvents : 'auto'}} onClick={() => this.buyCoins(1000)} className="buy_coins_container_shop">
                    <p className="buy_coins_text_shop">1000 {strings.coins_5}</p>
                    <p className="buy_coins_text_shop buy_coins_text_bold_shop">{strings.buy}</p>
                </div>
            </div>
           
        );
    }
}
export default BuyCoins;