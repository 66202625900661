import React from 'react';
import logo_white from '../pages_android/icons_android/logo_white.svg'

import { getLocalizedStrings } from '../LocalizedStrings';
import { getRequestParams, getRequestUrl, saveNewCookies, getCookie } from './custom_views/utils_android/CookieHelper';
import { getHttpParams } from './custom_views/utils_android/Utils';
import BtnLoader from './custom_views/earn_tasks/BtnLoader';
import { showAndroidToast } from './custom_views/utils_android/AndroidMethods';

let strings = getLocalizedStrings()
class Auth extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            username : "",
            isLoading : false
        }
    }
    usernameChanged = (event) => {
        if (event){
            this.setState({username : event.target.value})
        }
        
    }
    loadUserInfo = () => {
        this.setState({isLoading : true})
        var params = getRequestParams()
        params.username = this.state.username
        fetch(getRequestUrl() + "getInfoUser", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == "-1"){
                showAndroidToast(strings.authError)
            } else if (responseData){
                saveNewCookies(responseData)
                this.registerUser()
            }
            this.setState({isLoading : false})
            
            console.log("getInfoUser" + JSON.stringify(responseData))
        })
    }
    transferCoins = () => {
        const old_token = getCookie("old_token")
        if (old_token == undefined || old_token == "" || old_token == "undefined" || old_token == null){
            return
        }
        var params = {}
        params.old_user_id = old_token
        params.new_user_id = getCookie("user_id")

        console.log("transferCoins params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "transferCoins", getHttpParams(params)).then((response) => response.json()).then((responseData) => {
            console.log("transferCoins = " + JSON.stringify(params))
        })
    }
    registerUser = () => {
        var params = getRequestParams()
        params.timestamp = this.props.timestamp
        params.user_name = getCookie("uniqueId")
        params.avatar_url = getCookie("avatarLarger")
        console.log("registerUser params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "registerUser", getHttpParams(params)).then((response) => response.json()).then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.transferCoins()
                this.props.getMyInfo()
                this.props.openOrders()
                
            }
            this.setState({isLoading : false})
        })
    }
    render() {
        return (
            
            <div className="search_page main_container">
                <img className="logo_auth center_img"  src={logo_white}/>
                <div className="center_img auth_div_user">
                    <input placeholder={strings.usernamePlaceholder} className="auth_input" onChange={this.usernameChanged} value={this.state.username} />
                    <div className="username_border"/>
                </div>
                
                <div onClick={this.loadUserInfo} style={this.state.isLoading ? {pointerEvents : 'none'} : this.state.username.length < 2 ? {pointerEvents : 'none', opacity : 0.2} : {}} className="relative enter_btn">
                    <div className="btn-follow top_10">
                        {this.state.isLoading ? <BtnLoader style={{marginTop : '-4px'}}/> : strings.enterBtn}
                    </div>
                </div>
            </div>


        );
    }
}
export default Auth;