import React from 'react';
import ReactDOM from 'react-dom';
import Menu from './components_android/MenuAndroid'
import Navbar from './components_android/NavbarAndroid'
import qs from 'querystring'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import './css_android/style_android.css';

import { getUrlParameter } from './pages_android/custom_views/utils_android/UrlHelperAndroid';
import {saveRegistrCookie, getRequestParams, getRequestUrl, getToken, setIsTimestamped, getIsTimestamped, getCookie} from './pages_android/custom_views/utils_android/CookieHelper'
import { ReCaptcha } from 'react-recaptcha-v3'
import Loader from './pages_android/custom_views/utils_android/LoaderAndroid';
import BuyCoins from './pages_android/BuyCoins';
import Orders from './pages_android/Orders';
import { declension, getHttpParams } from './pages_android/custom_views/utils_android/Utils';
import Earn from './pages_android/Earn';
import LocalizedStrings from 'react-localization'
import SearchTags from './pages_android/SearchTags';
import PopularTags from './pages_android/PopularTags';
import MenuAndroid from './components_android/MenuAndroid';
import { getLocalizedStrings } from './LocalizedStrings';
import Auth from './pages_android/Auth';

let strings = getLocalizedStrings()

window.$moneyCount = 0.0
class Application extends React.Component {
   
    constructor(){
        super()
        this.state = {
            done : true,
            count_coins : 0,
            declension : 'монет',
            isTimestamped : false,
            isMenuOpened : false,
            activeView : 'orders',
            backView : undefined,

            isCreateOrder : false,
            isShowOrderInfo : false,
            isShowUpsocial : false,
        }
        this.onMenuClicked = this.onMenuClicked.bind(this);
        this.toogleMenu = this.toogleMenu.bind(this);
        this.onBack = this.onBack.bind(this);
        this.toggleCreateOrder = this.toggleCreateOrder.bind(this);
        this.toggleOrderInfo = this.toggleOrderInfo.bind(this);
        //console.log("location = " + window.location.href)

        //testing
        
        // var token = getUrlParameter('token')
        // saveRegistrCookie(token)
    }
    
    componentDidMount(){
        //loadReCaptcha("6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_");
        // this.getMyInfo()
        if ( window.location.href.includes('auth')){
            return
        }
        const uniqueId = getCookie("uniqueId")
        if (uniqueId == null || uniqueId == undefined || uniqueId == "" || uniqueId == "undefined") {
            window.location.href = '/auth'
        } else {
            this.getMyInfo()
            this.openOrders()
        }
    }
    toggleCreateOrder(){
        this.setState({isCreateOrder : !this.state.isCreateOrder})
    }
    toggleOrderInfo(){
        this.setState({isShowOrderInfo : !this.state.isShowOrderInfo})
    }
    openOrders = () => {
        console.log("this.state.activeView = " + this.state.activeView)
        //if (!this.state.activeView.includes("orders") && !this.state.activeView.includes("shop") && !this.state.activeView.includes("earn")){
        if (!window.location.href.includes("orders") && !window.location.href.includes("shop") && !window.location.href.includes("earn")){
            this.getAbstractInfo()
        }
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({timestamp : recaptchaToken})
        if (getToken() == null || getToken() == undefined || getToken() == "") {
            var token = getUrlParameter('token')
            //saveRegistrCookie(token)
            //this.registerUser()
        }
    }
    onMenuClicked(viewId){
        console.log("viewId = " + viewId)
        this.setState({activeView : viewId, backView : undefined, currentTask: {}})
        this.getMyInfo()
        this.reloadCaptcha()
    }
    reloadCaptcha = () => {
        // if (!this.state.isLoadCaptcha){
        //     this.setState({isLoadCaptcha : true})
        // }
    }
    
    
    getAbstractInfo = () => {
        if (getIsTimestamped() != 'isTimestamped'){
            var params = getRequestParams()
            fetch(getRequestUrl() + "getAbstractInfo", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        setIsTimestamped()
                        window.location.href = '/orders'
                        //this.setState({activeView : 'orders'})
                        this.setState({isShowUpsocial : true})
                    }
                })
        } else {
            this.setState({c : true})
            window.location.href = '/orders'
            //this.setState({activeView : 'orders'})
        }

    }
    
    getMyInfo = () => {
        this.setState({done : true})
        var params = getRequestParams()
        console.log("params getMyInfo = " + JSON.stringify(params))
        fetch(getRequestUrl() + "getMyCoins", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.count_coins){
                var count_coins = responseData.count_coins
                var decl = declension(count_coins, strings.coins_1, strings.coins_2, strings.coins_5)
                this.setState({count_coins : count_coins, declension : decl})
            }
            console.log("getMyCoins" + JSON.stringify(responseData))
            
        })
    }
  
    updateMoney = (money) => {
        var countMoney = this.state.count_coins + parseFloat(money)
        this.setState({count_coins : countMoney})
        console.log("updateMoney = " + countMoney)
    }
    setMoney = (money) => {
        this.setState({count_coins : money})
        console.log("setMoney = " + money)
    }
   
    toogleMenu(isOpened){
        if (isOpened != undefined){
            this.setState({isMenuOpened : isOpened})
        } else {
            this.setState({isMenuOpened : !this.state.isMenuOpened})
        }
    }
    onBack(viewId){
        this.setState({activeView : viewId, backView : undefined})
        this.reloadCaptcha()
    }
openUrl(url) {
        // window.open(url)
        // return
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.openUrl(url);
        } else {
            return false
        }
    }
    render() {
        console.log("render view = " + this.state.activeView)
        if (this.isShowUpsocial || getIsTimestamped() == "isTimestamped"){
            return(
                <div style={{height : '100vh', margin : 0}} className = "main">
                    <div className='full_center'>

                        <div className='promote_text'>
                            {strings.promoteText}
                        </div>
                        <div onClick={() => this.openUrl('https://upsocial.ru')} className="relative promote_btn">
                            <div className="btn-follow">
                                {strings.promoteAcc}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (!this.state.done){
            return(
            <div>
                <ReCaptcha
                    sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                    action='homepage'
                    verifyCallback={this.verifyCallback}
                />
                <Loader/>
            </div>) 
        } else return (
            <Router>
                <ReCaptcha
                    sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                    action='homepage'
                    verifyCallback={this.verifyCallback}
                />
                <div className="app">
                    <aside className = "aside">
                    <MenuAndroid toogleMenu={this.toogleMenu} isMenuOpened={this.state.isMenuOpened} onMenuClicked={this.onMenuClicked} isTimestamped={this.state.isTimestamped} count_coins={this.state.count_coins} />

                    </aside>
                    <div className = "main">
                            <Navbar isShowOrderInfo={this.state.isShowOrderInfo} isCreateOrder={this.state.isCreateOrder} toggleOrderInfo={this.toggleOrderInfo} toggleCreateOrder={this.toggleCreateOrder} toogleMenu={this.toogleMenu} onBack={this.onBack} backView={this.state.backView} count_coins={this.state.count_coins} declension={this.state.declension}/>
                                 <Switch>
                                    <Route path="/orders">
                                        <Orders updateMoney={this.updateMoney} isShowOrderInfo={this.state.isShowOrderInfo} isCreateOrder={this.state.isCreateOrder} toggleOrderInfo={this.toggleOrderInfo} toggleCreateOrder={this.toggleCreateOrder} isTimestamped={this.state.isTimestamped} count_coins={this.state.count_coins} onOrderCreate={this.onOrderCreate} timestamp={this.state.timestamp}/>
                                    </Route>
                                    <Route path="/shop">
                                        <BuyCoins isTimestamped={this.state.isTimestamped} setMoney={this.setMoney} timestamp={this.state.timestamp}/>
                                    </Route>
                                    <Route path="/earn">
                                        <Earn isTimestamped={this.state.isTimestamped} setMoney={this.setMoney} timestamp={this.state.timestamp}/>
                                    </Route>
                                    <Route path="/search">
                                        <SearchTags isTimestamped={this.state.isTimestamped} updateMoney={this.updateMoney} timestamp={this.state.timestamp} count_coins={this.state.count_coins}/>
                                    </Route>
                                    <Route path="/popular">
                                        <PopularTags isTimestamped={this.state.isTimestamped} updateMoney={this.updateMoney} timestamp={this.state.timestamp} count_coins={this.state.count_coins}/>
                                    </Route>
                                    <Route path="/auth">
                                        <Auth timestamp={this.state.timestamp} getMyInfo={this.getMyInfo} openOrders={this.openOrders}/>
                                    </Route>
                                </Switch>                            
                    </div>
                
                </div>
             </Router>
            
        );
    }
}

ReactDOM.render(

        <Application/>
   ,
    document.getElementById('root')
);
window.onResume = () => {
    console.log("onResume index")
    if (window.photoTask != undefined){
        window.photoTask.onResume();
    }
    if (window.shop != undefined){
        window.shop.onResume();
    }
}
window.coinsPurchased = (details) => {
    console.log("coinsPurchased index")
    if (window.shop != undefined){
        window.shop.coinsPurchased(details);
    }
}
window.coinsPurchasedUpdate = (count) => {
    console.log("coinsPurchasedUpdate 1 count")
    if (window.shop != undefined){
        window.shop.coinsPurchasedUpdate(count);
    }
}

