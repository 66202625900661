import qs from 'querystring'
export const declension = (count, one, two, five) => {
    var realCount = count;
  
    if (count > 100)
        count %= 100;
  
    if (count > 20)
        count %= 10;
  
    switch (count) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return two;
        default:
            return five;
    }
  }
  export const getHttpParams = (params) => {
    var httpParams = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: qs.stringify(params)
    }
    return httpParams;
};
export const getPopularTags = () => {
    var tags = [{name : "recomendation", formatted_media_count : "5.3B"}, {name : "recomendations", formatted_media_count : "8.4B"}, {name : "recommendation", formatted_media_count : "630.8M"}, {name : "rec", formatted_media_count : "5.5B"}, {name : "recipe", formatted_media_count : "7.6B"}, {name : "dog", formatted_media_count : "58.7B"}, {name : "petlover", formatted_media_count : "19.0B"}, {name : "dogs", formatted_media_count : "18.8B"}, {name : "doggo", formatted_media_count : "5.8B"}, {name : "popular", formatted_media_count : "11.3B"}, {name : "cat", formatted_media_count : "31.6B"}, {name : "catlover", formatted_media_count : "5.0B"}, {name : "trend", formatted_media_count : "287.3B"}, {name : "trending", formatted_media_count : "837.1B"}, {name : "newtrend", formatted_media_count : "24.3B"}, {name : "tiktoktrend", formatted_media_count : "2.0B"}]
    return tags
};
export const getTestOrder = () => {
    return {
        "id":"6798465163000368390",
        "desc":"#лабрадор #dog #уши #хочуврек",
        "createTime":"1582891024",
        "scheduleTime":0,
        "video":{
           "id":"6798465163000368390",
           "height":960,
           "width":540,
           "duration":21,
           "ratio":"720p",
           "cover":"https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/8e5c26c5eae347d38106c36da5cd67dc_1582891332?x-expires=1655146800&x-signature=YQgYfhtDrAJthu%2BHUxaLDKRQoSk%3D",
           "originCover":"https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/9403a5a9aaeb476c9cc500efb570c6a8_1582891332?x-expires=1655146800&x-signature=wHlMxtQF0wenvSTIqmZMsaPyaJY%3D",
           "dynamicCover":"https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/4b880886609d4018b5c756e1b4691fb9_1582891332?x-expires=1655146800&x-signature=v1FCSHS4FEhhzuD%2BcgPqODSHOHM%3D",
           "playAddr":"https://v16-webapp.tiktok.com/011359302d6b6da87bff69b360d739c7/62a79562/video/tos/useast2a/tos-useast2a-ve-0068c003/5f0db2fe1a5f45189c5bfc4e643579ba/?a=1988&ch=0&cr=0&dr=0&lr=tiktok_m&cd=0%7C0%7C0%7C0&br=1368&bt=684&btag=80000&cs=0&ds=3&ft=eXd.6HJ9Myq8ZQgaQwe2Nihjyl7Gb&mime_type=video_mp4&qs=0&rc=ZjVoOzQ2aDU7ODs3M2ZlM0BpM2U2eHRkeHVpczMzaTczM0BgNmNhNjM2NmExMDBiMDAxYSMtaWZrZGAwc2BfLS0tMTZzcw%3D%3D&l=202206131351410102171342040016729F",
           "downloadAddr":"https://v16-webapp.tiktok.com/011359302d6b6da87bff69b360d739c7/62a79562/video/tos/useast2a/tos-useast2a-ve-0068c003/5f0db2fe1a5f45189c5bfc4e643579ba/?a=1988&ch=0&cr=0&dr=0&lr=tiktok_m&cd=0%7C0%7C0%7C0&br=1368&bt=684&btag=80000&cs=0&ds=3&ft=eXd.6HJ9Myq8ZQgaQwe2Nihjyl7Gb&mime_type=video_mp4&qs=0&rc=ZjVoOzQ2aDU7ODs3M2ZlM0BpM2U2eHRkeHVpczMzaTczM0BgNmNhNjM2NmExMDBiMDAxYSMtaWZrZGAwc2BfLS0tMTZzcw%3D%3D&l=202206131351410102171342040016729F",
           "shareCover":[
              "",
              "https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/9403a5a9aaeb476c9cc500efb570c6a8_1582891332~tplv-tiktok-play.jpeg?x-expires=1655730000&x-signature=wQvzGBLvQU5qzoUs9vcblaw24JI%3D",
              "https://p77-sign-va-lite.tiktokcdn.com/tos-maliva-p-0068/9403a5a9aaeb476c9cc500efb570c6a8_1582891332~tplv-tiktokx-share-play.jpeg?x-expires=1655730000&x-signature=SVvJLSSdlV%2BZg14qK%2FJ3BwFdCT0%3D"
           ],
           "reflowCover":"https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/8e5c26c5eae347d38106c36da5cd67dc_1582891332?x-expires=1655146800&x-signature=YQgYfhtDrAJthu%2BHUxaLDKRQoSk%3D",
           "bitrate":700947,
           "encodedType":"normal",
           "format":"mp4",
           "videoQuality":"normal",
           "encodeUserTag":"",
           "codecType":"h264",
           "definition":"720p",
           "subtitleInfos":[
              
           ]
        },
        "author":"evgeny_psv",
        "music":{
           "id":"6798392531970231046",
           "title":"оригинальный звук",
           "playUrl":"https://sf16-ies-music-va.tiktokcdn.com/obj/musically-maliva-obj/1659771922862134.mp3",
           "coverLarge":"https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1655719747167237~c5_1080x1080.jpeg?x-expires=1655298000&x-signature=FW0cL4iWxEfBbNU5PK5YGq2Caz0%3D",
           "coverMedium":"https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1655719747167237~c5_720x720.jpeg?x-expires=1655298000&x-signature=HGryarhxB5Ilwi%2FDfeWuTYKrD08%3D",
           "coverThumb":"https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1655719747167237~c5_100x100.jpeg?x-expires=1655298000&x-signature=B%2BXGban4jqJo29fmHyJiBRrL8dI%3D",
           "authorName":"Антон Докучаев",
           "original":true,
           "duration":21,
           "album":"",
           "scheduleSearchTime":0
        },
        "challenges":[
           {
              "id":"12560364",
              "title":"лабрадор",
              "desc":"",
              "profileLarger":"",
              "profileMedium":"",
              "profileThumb":"",
              "coverLarger":"",
              "coverMedium":"",
              "coverThumb":"",
              "isCommerce":false,
              "stats":{
                 "videoCount":0,
                 "viewCount":0
              }
           },
           {
              "id":"6221",
              "title":"dog",
              "desc":"",
              "profileLarger":"",
              "profileMedium":"",
              "profileThumb":"",
              "coverLarger":"",
              "coverMedium":"",
              "coverThumb":"",
              "isCommerce":false,
              "stats":{
                 "videoCount":0,
                 "viewCount":0
              }
           },
           {
              "id":"21263043",
              "title":"уши",
              "desc":"",
              "profileLarger":"",
              "profileMedium":"",
              "profileThumb":"",
              "coverLarger":"",
              "coverMedium":"",
              "coverThumb":"",
              "isCommerce":false,
              "stats":{
                 "videoCount":0,
                 "viewCount":0
              }
           },
           {
              "id":"1596093786694662",
              "title":"хочуврек",
              "desc":"",
              "profileLarger":"",
              "profileMedium":"",
              "profileThumb":"",
              "coverLarger":"",
              "coverMedium":"",
              "coverThumb":"",
              "isCommerce":false,
              "stats":{
                 "videoCount":0,
                 "viewCount":0
              }
           }
        ],
        "stats":{
           "diggCount":43,
           "shareCount":0,
           "commentCount":1,
           "playCount":168
        },
        "duetInfo":{
           "duetFromId":"0"
        },
        "warnInfo":[
           
        ],
        "originalItem":false,
        "officalItem":false,
        "textExtra":[
           {
              "awemeId":"",
              "start":0,
              "end":9,
              "hashtagId":"12560364",
              "hashtagName":"лабрадор",
              "type":1,
              "subType":0,
              "userId":"",
              "isCommerce":false,
              "userUniqueId":"",
              "secUid":""
           },
           {
              "awemeId":"",
              "start":10,
              "end":14,
              "hashtagId":"6221",
              "hashtagName":"dog",
              "type":1,
              "subType":0,
              "userId":"",
              "isCommerce":false,
              "userUniqueId":"",
              "secUid":""
           },
           {
              "awemeId":"",
              "start":15,
              "end":19,
              "hashtagId":"21263043",
              "hashtagName":"уши",
              "type":1,
              "subType":0,
              "userId":"",
              "isCommerce":false,
              "userUniqueId":"",
              "secUid":""
           },
           {
              "awemeId":"",
              "start":20,
              "end":29,
              "hashtagId":"1596093786694662",
              "hashtagName":"хочуврек",
              "type":1,
              "subType":0,
              "userId":"",
              "isCommerce":false,
              "userUniqueId":"",
              "secUid":""
           }
        ],
        "secret":false,
        "forFriend":false,
        "digged":false,
        "itemCommentStatus":0,
        "showNotPass":false,
        "vl1":false,
        "takeDown":0,
        "itemMute":false,
        "effectStickers":[
           
        ],
        "authorStats":{
           "followerCount":202,
           "followingCount":13,
           "heart":3998,
           "heartCount":3998,
           "videoCount":16,
           "diggCount":965
        },
        "privateItem":false,
        "duetEnabled":true,
        "stitchEnabled":true,
        "stickersOnItem":[
           
        ],
        "isAd":false,
        "shareEnabled":true,
        "comments":[
           
        ],
        "duetDisplay":0,
        "stitchDisplay":0,
        "indexEnabled":true,
        "adAuthorization":false,
        "adLabelVersion":0,
        "nickname":"Антон Докучаев",
        "authorId":"6781826879689114629",
        "authorSecId":"MS4wLjABAAAAa_j74FlPf6k_G7E3P4LCoordREEJMZStlQStJp3F7gZm_NtrjuObBAd5mlqsA2uj",
        "avatarThumb":"https://p16-sign-va.tiktokcdn.com/musically-maliva-obj/1655719747167237~c5_100x100.jpeg?x-expires=1655298000&x-signature=B%2BXGban4jqJo29fmHyJiBRrL8dI%3D"
     }
};
